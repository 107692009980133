import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import { IconButton, Stack } from '@mui/material'
import { ReactElement } from 'react'
import COLORS from '../styles/colors'

export type SocialIconButtonProps = {
  icon: ReactElement
  href: string
  ariaLabel: string
}

export const SocialIconButton = ({
  href,
  icon,
  ariaLabel,
}: SocialIconButtonProps) => {
  return (
    <a
      href={href}
      rel="noreferrer"
      target="_blank"
      aria-label={`Link to ${ariaLabel}`}
    >
      <IconButton
        aria-label={`${ariaLabel} button`}
        sx={{
          backgroundColor: COLORS.burnedOrange,
          color: '#fff',
          borderRadius: '50%',
          fontSize: '30px',
          width: '40px',
          height: '40px',
          display: 'grid',
          placeItems: 'center',
          '&:hover': {
            backgroundColor: COLORS.burnedOrange,
            '*': {
              color: 'white',
            },
          },
        }}
      >
        {icon}
      </IconButton>
    </a>
  )
}

const socialMediaIcons = [
  {
    name: 'Facebook',
    icon: <FacebookIcon color="inherit" />,
    href: 'https://www.facebook.com/humanlibraryorg/',
  },
  {
    name: 'Instagram',
    icon: <InstagramIcon color="inherit" />,
    href: 'https://www.instagram.com/humanlibraryorganization/',
  },
  {
    name: 'Twitter',
    icon: <TwitterIcon color="inherit" />,
    href: 'https://twitter.com/TheHumanLibrary',
  },
  {
    name: 'LinkedIn',
    icon: <LinkedInIcon color="inherit" />,
    href: 'https://www.linkedin.com/company/human-library-organization/',
  },
]

const FooterSocialButtons = () => {
  return (
    <Stack direction="row" spacing="12px">
      {socialMediaIcons.map(({ name, href, icon }) => (
        <SocialIconButton ariaLabel={name} href={href} icon={icon} key={name} />
      ))}
    </Stack>
  )
}

export default FooterSocialButtons
