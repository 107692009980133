export default function getHyperlinks(message: string | undefined) {
  if (message === undefined) return ''

  const regex =
    /(?![^<]*>|[^<>]*<\/)(https?:\/\/|www\.)[a-zA-Z0-9&#=.\/\-?_]+\.[a-zA-Z]{2,}(?:[^\s]*)?/gi

  const hyperlinkedText = message.replace(regex, (match) => {
    let href = match
    if (match.startsWith('www.')) {
      href = 'http://' + match
    }
    return `<a style="font-weight:600;text-decoration:underline;" href="${href}">${match}</a>`
  })

  return hyperlinkedText
}
