import { Box, Paper, Stack, useMediaQuery } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'
import COLORS from '../styles/colors'
import FooterSocialButtons from './FooterSocialButtons'
import PageContainer from './PageContainer'
import { useRouter } from 'next/router'

const Footer = () => {
  const router = useRouter()

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'))

  if (router.pathname.includes('/reading-room')) {
    return null
  }

  return (
    <Paper
      component="footer"
      sx={{
        backgroundColor: COLORS.greyDark,
      }}
    >
      <PageContainer disableGutters>
        <Box
          sx={{
            color: '#fff',
            paddingLeft: isSmall ? '2rem' : '200px',
            paddingRight: isSmall ? '2rem' : '350px',
            paddingY: '2rem',
            paddingBottom: isSmall ? '2rem' : '60px',
            paddingTop: isSmall ? '2rem' : '40px',
          }}
        >
          <Stack
            direction={isSmall ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Stack direction="column" spacing="20px" maxWidth="630px">
              <Typography component="h1" variant="footerTitle">
                THE HUMAN LIBRARY
              </Typography>
              <Typography variant="inter_smallText">
                Not for profit organization based in Copenhagen, Denmark with
                partners in more than 80 countries across the world.
              </Typography>

              <Box>
                <Typography variant="inter_smallText">
                  Human Library Organization, Serving Readers Since 2000 © 2024
                  All rights reserved
                </Typography>
              </Box>
            </Stack>
            <Stack spacing="20px">
              <Typography variant="inter_smallText">
                Follow us online
              </Typography>
              <FooterSocialButtons />
              <Typography variant="inter_smallText">
                Support:{' '}
                <a
                  href="mailto:support@humanlibrary.org"
                  style={{ color: COLORS.orange }}
                >
                  support@humanlibrary.org
                </a>
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </PageContainer>
    </Paper>
  )
}

export default Footer
