import getIntlDate from './getIntlDate'

export default function formatReadingDate(
  languageLocale: string,
  readingDate: Date | null,
  withYear?: boolean
) {
  if (!readingDate || readingDate instanceof Date === false) {
    return null
  }
  if (withYear) {
    return getIntlDate(readingDate, languageLocale, {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    })
  }
  return getIntlDate(readingDate, languageLocale, {
    month: 'long',
    day: '2-digit',
  })
}
