import { useMutation } from '@apollo/client'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Tooltip,
} from '@mui/material'
import { useTranslation } from 'next-i18next'
import React, { useState } from 'react'
import {
  InsertMessageMutation,
  InsertMessageMutationVariables,
} from '../../../../generated/graphql'
import InsertMessage from '../../../../graphql/mutations/InsertMessage'
import COLORS from '../../../../styles/colors'

export type ChatMessageInputFieldProps = {
  disabled: boolean
  userId: string
}

const ChatMessageInputField = ({
  disabled,
  userId,
}: ChatMessageInputFieldProps) => {
  const { t } = useTranslation('Chat')
  const [value, setValue] = useState('')
  const [sendMessage, { loading, error }] = useMutation<
    InsertMessageMutation,
    InsertMessageMutationVariables
  >(InsertMessage)

  const handleOnChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setValue(event.target.value)
  }

  const isValid = typeof value === 'string' && value.trim().length > 0

  const handleOnKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (
    event
  ) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    if (isValid) {
      sendMessage({
        variables: {
          userId: userId,
          value,
        },
      })
      setValue('')
    }
  }

  return (
    <FormControl>
      <InputLabel htmlFor="chat-input" aria-label="chat input" />
      <TextField
        id="chat-input"
        autoFocus
        disabled={loading || disabled}
        fullWidth
        margin="normal"
        onChange={handleOnChange}
        onKeyPress={handleOnKeyPress}
        multiline
        minRows={1}
        maxRows={5}
        placeholder={t('chatMessageInputField.placeholder')}
        sx={{
          mb: 0,
          '& .MuiOutlinedInput-root': {
            height: '100%',
          },
        }}
        type="text"
        value={value}
        variant="outlined"
        InputLabelProps={{ 'aria-label': 'chat-input' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={t('chatMessageInputField.buttonTooltip')}>
                <IconButton
                  aria-label="send message"
                  onClick={handleSubmit}
                  disabled={(loading || error || !isValid) as boolean}
                  sx={{
                    color: COLORS.burnedOrange,
                  }}
                >
                  <FontAwesomeIcon
                    color="inherit"
                    icon={faPaperPlane}
                    style={{ width: '28px' }}
                  />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  )
}

export default ChatMessageInputField
