import { useApolloClient } from '@apollo/client'
import { useEffect } from 'react'
import useAuthClaims from '../../../hooks/useAuthClaims'
import { useAppDispatch, useAppSelector } from '../../../store'
import {
  fetchNumberOfBooksInReadingPlan,
  fetchReadingPlanByUserId,
  selectPillarConstraints,
} from '../slices/readingPlanSlice'

const ReadingPlanDataContainer = () => {
  const dispatch = useAppDispatch()
  const client = useApolloClient()
  const pillarConstraints = useAppSelector(selectPillarConstraints)
  const { claims, userId } = useAuthClaims()

  const userRole = claims?.role || ''

  useEffect(() => {
    if (userId && ['reader', 'superreader'].includes(userRole)) {
      dispatch(fetchReadingPlanByUserId({ client, variables: { userId } }))
    }
  }, [userRole, userId])

  useEffect(() => {
    if (Array.isArray(pillarConstraints)) {
      dispatch(fetchNumberOfBooksInReadingPlan({ client, pillarConstraints }))
    }
  }, [pillarConstraints])

  return null
}

export default ReadingPlanDataContainer
