import { gql, useApolloClient, useMutation } from '@apollo/client'
import { useEffect } from 'react'
import {
  ChatRoomGetListSubscription,
  ChatRoomGetListSubscriptionVariables,
  GetChatMessagesSubscription,
  GetChatMessagesSubscriptionVariables,
  UpdateChatMessageUnreadMutation,
  UpdateChatMessageUnreadMutationVariables,
  useChatRoomJoinMutation,
} from '../../../generated/graphql'
import UpdateChatMessageUnread from '../../../graphql/mutations/UpdateChatMessageUnread'
import useAuthClaims from '../../../hooks/useAuthClaims'
import useCustomSubscription from '../../../hooks/useCustomSubscription'
import { useAppDispatch, useAppSelector } from '../../../store'
import useLocalization from '../../localization/hooks/useLocalization'
import { selectChatToUserId } from '../slices/chatDrawerSlice'
import {
  fetchChatMessageTypes,
  selectChatMessageTypeCalled,
  selectChatMessageTypeData,
} from '../slices/chatMessageTypesSlice'
import getChatVariant from '../util/getChatVariant'
import ChatRoom from '../views/ChatRoom'
import * as Sentry from '@sentry/nextjs'

const GET_CHAT_MESSAGES = gql`
  subscription GetChatMessages($userId: uuid!) {
    chatMessages: chat_message(
      order_by: { created: asc }
      where: {
        chatMessageType: { name: { _in: ["message", "joined", "resolved"] } }
        user_id: { _eq: $userId }
      }
    ) {
      id
      created
      from_user_id
      fromUser {
        role {
          name
        }
      }
      user_id
      value
      fromPersonName
      unread
      chatMessageType {
        id
        name
      }
    }
  }
`

const GET_CHAT_ROOMS = gql`
  subscription ChatRoomGetList($userId: uuid!) {
    chat_room_by_pk(user_id: $userId) {
      owner_user_id
      user_id
      active
    }
  }
`

const JOIN_CHAT_ROOM = gql`
  mutation ChatRoomJoin(
    $messageJoinTypeId: uuid!
    $ownerUserId: uuid!
    $userId: uuid!
  ) {
    insert_chat_room_one(
      object: { active: true, owner_user_id: $ownerUserId, user_id: $userId }
      on_conflict: {
        constraint: chat_room_pkey
        update_columns: [active, owner_user_id]
      }
    ) {
      user_id
    }

    insert_chat_message_one(
      object: { chat_message_type_id: $messageJoinTypeId, user_id: $userId }
    ) {
      id
    }
  }
`

const ChatRoomContainer = () => {
  const { language } = useLocalization()
  const dispatch = useAppDispatch()
  const client = useApolloClient()
  const { claims, userId } = useAuthClaims()
  const toUserId = useAppSelector(selectChatToUserId)
  const messageTypesCalled = useAppSelector(selectChatMessageTypeCalled)
  const variant =
    (claims && getChatVariant(claims?.role, toUserId)) || undefined
  const messageTypes = useAppSelector(selectChatMessageTypeData)
  const queries = {
    messages: useCustomSubscription<
      GetChatMessagesSubscription,
      GetChatMessagesSubscriptionVariables
    >({
      query: GET_CHAT_MESSAGES,
      skip: !userId || typeof toUserId !== 'string' || toUserId.length === 0,
      variables: { userId: toUserId },
    }),
    chatRoom: useCustomSubscription<
      ChatRoomGetListSubscription,
      ChatRoomGetListSubscriptionVariables
    >({
      query: GET_CHAT_ROOMS,
      skip:
        !userId ||
        typeof toUserId !== 'string' ||
        toUserId.length === 0 ||
        variant !== 'admin',
      variables: {
        userId: toUserId,
      },
    }),
  }

  useEffect(() => {
    if (!messageTypesCalled && userId && toUserId && variant === 'admin') {
      dispatch(fetchChatMessageTypes({ client }))
    }
  }, [messageTypesCalled, userId, toUserId, variant])

  const messages = queries.messages.data
  const messagesError = queries.messages.error
  const messagesLoading = queries.messages.loading
  const messageJoinTypeId =
    (Array.isArray(messageTypes) &&
      messageTypes.find(({ name }) => name === 'joined')?.id) ||
    undefined

  const chatRoom = queries.chatRoom.data || undefined
  const chatRoomLoading = queries.chatRoom.loading

  const [joinRoom] = useChatRoomJoinMutation({
    variables: {
      messageJoinTypeId,
      ownerUserId: userId,
      userId: toUserId,
    },
  })

  const [setChatMessagesRead, { error: updateError }] = useMutation<
    UpdateChatMessageUnreadMutation,
    UpdateChatMessageUnreadMutationVariables
  >(UpdateChatMessageUnread)

  const isActive = !!chatRoom?.chat_room_by_pk?.active
  const isOwner = chatRoom?.chat_room_by_pk?.owner_user_id === userId

  const chatMessages = messages?.chatMessages || []

  if (!!messagesError) {
    Sentry.captureMessage(`ChatRoom Messages Error with user: ${userId}`)
  }

  if (!!updateError) {
    Sentry.captureMessage(`ChatRoom Update Error with user: ${userId}`)
  }

  useEffect(() => {
    const ids =
      chatMessages
        .filter(({ from_user_id, unread }) => {
          return unread && from_user_id !== userId
        })
        .map(({ id }) => id) || []

    if (ids.length > 0) {
      setChatMessagesRead({
        variables: {
          ids,
        },
      })
    }
  }, [chatMessages, toUserId])

  const handleJoinClick = () => {
    joinRoom()
  }

  if (!toUserId || !variant) {
    return null
  }

  const isResolved =
    (chatMessages.length > 0 &&
      chatMessages[chatMessages.length - 1].chatMessageType.name ===
        'resolved') ||
    false

  return (
    <ChatRoom
      error={!!messagesError || !!updateError}
      language={language}
      loading={chatRoomLoading || messagesLoading}
      messages={chatMessages}
      onJoinClick={handleJoinClick}
      showJoinChatButton={
        variant === 'admin' && (!isOwner || isResolved || !isActive)
      }
      userId={toUserId}
      variant={variant}
    />
  )
}

export default ChatRoomContainer
