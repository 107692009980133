import { ApolloClient, FetchResult } from '@apollo/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  InsertOrganisationMutation,
  InsertOrganisationMutationVariables,
} from '../../../generated/graphql'
import { RootState } from '../../../store'
import InsertOrganisation from '../graphql/mutations/InsertOrganisation'

export const insertOrganisation = createAsyncThunk<
  FetchResult<InsertOrganisationMutation>,
  {
    client: ApolloClient<object>
    variables: InsertOrganisationMutationVariables
  },
  { state: RootState }
>('organisations/insert', async ({ client, variables }) => {
  return await client.mutate<
    InsertOrganisationMutation,
    InsertOrganisationMutationVariables
  >({
    fetchPolicy: 'no-cache',
    mutation: InsertOrganisation,
    variables,
  })
})
