import { ApolloClient, ApolloQueryResult } from '@apollo/client'
import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  NotificationsGetChatMessagesQuery,
  NotificationsGetChatMessagesQueryVariables,
} from '../../../generated/graphql'
import { RootState } from '../../../store'
import { getNotifications } from '../services/getNotifications'

export type NotificationsState = {
  called?: boolean
  data?: NotificationsGetChatMessagesQuery['view_chat_message_with_last_message'][0]['chatMessage'][]
  error?: SerializedError
  loading?: boolean
}

const initialState: NotificationsState = {}

export const fetchNotifications = createAsyncThunk<
  ApolloQueryResult<NotificationsGetChatMessagesQuery>,
  {
    client: ApolloClient<object>
  },
  { state: RootState }
>('notificationList/fetch', async ({ client }, thunkAPI) => {
  const state = thunkAPI.getState()

  const where: NotificationsGetChatMessagesQueryVariables['where'] = {
    chatMessage: {
      chatMessageType: {
        name: {
          _in: [
            'message',
            'cancel_all_readings',
            'did_not_join_reading',
            'kick',
            'joined',
            'resolved',
          ],
        },
      },
    },
  }

  // fix typescript thinking chatMessage is undefined
  if (!where.chatMessage) {
    where.chatMessage = {}
  }

  if (state.notificationFilters.selected === 'new') {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    where.chatMessage.created = {
      _gte: yesterday.toISOString(),
    }
  } else if (state.notificationFilters.selected === 'in_progress') {
    where.chatMessage.chatRoom = {
      active: { _eq: true },
      owner_user_id: { _is_null: false },
    }
  } else if (state.notificationFilters.selected === 'solved') {
    where.chatMessage.chatRoom = {
      active: { _eq: false },
      owner_user_id: { _is_null: false },
    }

    where.lastChatMessage! = {
      chatMessageType: {
        name: { _eq: 'resolved' },
      },
    }
  }

  return getNotifications(client, { where })
})

export const notificationListSlice = createSlice({
  name: 'notificationList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.error = undefined
        state.loading = true
      })
      .addCase(fetchNotifications.fulfilled, (state, { payload }) => {
        state.called = true

        state.data = _.sortBy(
          payload.data.view_chat_message_with_last_message.map(
            (item) => item.chatMessage
          ),
          function (chatMessage) {
            return new Date(chatMessage?.created)
          }
        ).reverse()

        state.loading = false
      })
      .addCase(fetchNotifications.rejected, (state, { error }) => {
        state.called = true
        state.loading = false
        state.error = error
      })
  },
})

export const selectNotificationsCalled = ({ notifications }: RootState) => {
  return notifications.called
}

export const selectNotificationsData = ({ notifications }: RootState) => {
  return notifications.data
}

export const selectNotificationsError = ({ notifications }: RootState) => {
  return notifications.error
}

export const selectNotificationsLoading = ({ notifications }: RootState) => {
  return notifications.loading
}
