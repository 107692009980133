import { gql } from '@apollo/client'
import { useEffect } from 'react'
import { useChatGetUserQuery } from '../../../generated/graphql'
import useAuthClaims from '../../../hooks/useAuthClaims'
import { useAppDispatch, useAppSelector } from '../../../store'
import { HLO } from '../../../types/global'
import {
  selectChatDrawerOpen,
  selectChatToUserId,
  setChatToUserId,
  toggleChat,
} from '../slices/chatDrawerSlice'
import getChatVariant from '../util/getChatVariant'
import ChatDrawer from '../views/ChatDrawer'

const GET_USER = gql`
  query ChatGetUser($userId: uuid!) {
    user_by_pk(id: $userId) {
      person {
        name
        pronouns
      }

      role {
        name
      }
    }
  }
`

export type ChatDrawerContainerProps = {
  children: JSX.Element | JSX.Element[]
}

const ChatDrawerContainer = ({ children }: ChatDrawerContainerProps) => {
  const dispatch = useAppDispatch()
  const { claims } = useAuthClaims()
  const open = useAppSelector(selectChatDrawerOpen)
  const toUserId = useAppSelector(selectChatToUserId)

  const { data, error, loading } = useChatGetUserQuery({
    skip: !open || !toUserId,
    variables: {
      userId: toUserId,
    },
  })

  const fromUser = claims
    ? {
        id: claims.id,
        role: { name: claims.role as HLO.Role },
      }
    : undefined

  const toUser =
    toUserId && data && data.user_by_pk
      ? data.user_by_pk && {
          id: toUserId,
          person: {
            name: data.user_by_pk.person.name || undefined,
            pronouns: data.user_by_pk.person.pronouns || undefined,
          },
          role: { name: data.user_by_pk.role.name as HLO.Role },
        }
      : undefined

  let variant = claims && getChatVariant(claims.role, toUserId)

  const handleRoomChange = (value: string | undefined) => {
    dispatch(setChatToUserId(value))
  }

  const handleHideDrawer = () => {
    dispatch(setChatToUserId(undefined))
    dispatch(toggleChat())
  }

  const handleToggleChat = (userId: string | undefined = undefined) => {
    dispatch(toggleChat())

    if (typeof userId === 'string') {
      dispatch(setChatToUserId(userId))
    }
  }

  useEffect(() => {
    if (open && !toUserId && variant === 'user') {
      if (fromUser) {
        dispatch(setChatToUserId(fromUser.id))
      }
    }
  })

  return (
    <>
      {fromUser && variant && (
        <ChatDrawer
          onClose={handleHideDrawer}
          onRoomChange={handleRoomChange}
          onToggle={handleToggleChat}
          open={open}
          toUser={toUser}
          variant={variant}
        >
          {children}
        </ChatDrawer>
      )}
    </>
  )
}

export default ChatDrawerContainer
