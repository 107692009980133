import { ApolloClient } from '@apollo/client'
import {
  Book_Bool_Exp,
  GetBookAggregateQuery,
  GetBookAggregateQueryVariables,
} from '../../../generated/graphql'
import GetBookAggregate from '../../../graphql/queries/GetBookAggregate'
import { ReadingPlanState } from '../slices/readingPlanSlice'

export default async function getNumberOfBooksInReadingPlan(
  apolloClient: ApolloClient<object>,
  pillarConstraints: ReadingPlanState['pillarConstraints']
) {
  let where: Book_Bool_Exp | undefined = undefined

  if (Array.isArray(pillarConstraints) && pillarConstraints.length > 0) {
    where = {
      book_topics: {
        topic: {
          pillar_topics: {
            pillar_id: { _in: pillarConstraints.map(({ id }) => id) },
          },
        },
      },
    }
  }

  const { data } = await apolloClient.query<
    GetBookAggregateQuery,
    GetBookAggregateQueryVariables
  >({
    fetchPolicy: 'no-cache',
    query: GetBookAggregate,
    variables: {
      where,
    },
  })

  return data.book_aggregate.aggregate?.count || 0
}
