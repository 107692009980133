import { ApolloClient, FetchResult } from '@apollo/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  UpdateOnboardingReadingPlanAcceptMutation,
  UpdateOnboardingReadingPlanAcceptMutationVariables,
} from '../../../generated/graphql'
import { RootState } from '../../../store'
import UpdateOnboardingReadingPlanAccept from '../graphql/mutations/UpdateOnboardingReadingPlanAccept'

export const updateOnboardingReadingPlanAccept = createAsyncThunk<
  FetchResult<UpdateOnboardingReadingPlanAcceptMutation>,
  {
    client: ApolloClient<object>
    variables: UpdateOnboardingReadingPlanAcceptMutationVariables
  },
  { state: RootState }
>(
  'onboardingReadingPlanAccept/insert',
  async ({ client, variables }, thunkAPI) => {
    return client.mutate<
      UpdateOnboardingReadingPlanAcceptMutation,
      UpdateOnboardingReadingPlanAcceptMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: UpdateOnboardingReadingPlanAccept,
      variables,
    })
  }
)
