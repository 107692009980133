import { useApolloClient } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'
import useAuthClaims from '../../../hooks/useAuthClaims'
import { useAppDispatch, useAppSelector } from '../../../store'
import useLocalization from '../hooks/useLocalization'
import {
  fetchLocalization,
  selectDefaultLanguage,
  selectLocalizationCalled,
  selectLocalizationLoading,
  setLanguage,
} from '../slices/localizationSlice'

const LocalizationContainer = () => {
  const client = useApolloClient()
  const dispatch = useAppDispatch()
  const { userId } = useAuthClaims()

  const called = useAppSelector(selectLocalizationCalled)
  const loading = useAppSelector(selectLocalizationLoading)

  const defaultLanguage = useAppSelector(selectDefaultLanguage)
  const { language } = useLocalization()

  useEffect(() => {
    if (userId && !loading) {
      if (!language && defaultLanguage) {
        dispatch(setLanguage(defaultLanguage))
        Sentry.captureMessage(
          'User language not set, falling back to default language',
          {
            user: {
              id: userId,
            },
          }
        )
      }
      if (!called || !language) {
        dispatch(fetchLocalization({ client, variables: { userId } }))
      }
    }
  }, [defaultLanguage, called, language, loading, userId])

  return null
}

export default LocalizationContainer
