import { createAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { NotificationItemStatusType } from '../components/NotificationItem/NotificationItemStatus'

export type NotificationFiltersState = {
  selected: NotificationItemStatusType | undefined
}

const initialState: NotificationFiltersState = {
  selected: undefined,
}

export const setSelectedNotificationFilters = createAction<
  NotificationItemStatusType | undefined
>('notificationFilters/setSelected')

export const notificationFiltersSlice = createSlice({
  name: 'notificationFilters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSelectedNotificationFilters, (state, { payload }) => {
      state.selected = payload
    })
  },
})

export const selectSelectedNotificationFilters = ({
  notificationFilters,
}: RootState) => {
  return notificationFilters.selected
}
