import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ListItemText, Stack, Typography } from '@mui/material'
import COLORS from '../../../../styles/colors'
import { HLO } from '../../../../types/global'
import { getIconByRole } from '../../util/getIconByRole'
import ChatDate from '../ChatDate'
import ChatMessageListItem, {
  ChatMessageListItemProps,
} from './ChatMessageListItem'
import getHyperlinks from '../../util/getHyperlinks'

export type AdminChatMessageBubbleProps = {
  date: Date
  fromName: string
  fromUserId: string
  fromUserRole: HLO.Role
  language?: HLO.Language
  ref?: ChatMessageListItemProps['ref']
  toUserId: string
  value: string | undefined
}

const AdminChatMessageBubble = ({
  date,
  fromName,
  fromUserRole,
  language,
  value,
}: AdminChatMessageBubbleProps) => {
  return (
    <ChatMessageListItem sx={{ pt: '16px', px: '20px', pb: '16px' }}>
      <ListItemText
        sx={{
          background: COLORS.white,
          border: `1px solid ${COLORS.borderGrey}`,
          borderRadius: '8px',
          boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.04)',
          color: COLORS.greyDark,
          fontSize: '0.9rem',
          fontWeight: 500,
          py: '12px',
          px: '16px',
        }}
        primary={
          <Stack
            alignItems="center"
            direction="row"
            height="12px"
            spacing="8px"
          >
            <FontAwesomeIcon
              color={COLORS.burnedOrange}
              icon={getIconByRole(fromUserRole)}
              style={{ height: '100%' }}
            />

            <Typography
              color={COLORS.burnedOrange}
              fontSize="14px"
              variant="inter_h6"
            >
              {fromName}
            </Typography>
          </Stack>
        }
        secondary={
          <Stack pt="15px" spacing="8px">
            <Typography color={COLORS.greyDark}>
              <span
                dangerouslySetInnerHTML={{ __html: getHyperlinks(value) }}
              ></span>
            </Typography>
            <ChatDate date={date} language={language} />
          </Stack>
        }
      />
    </ChatMessageListItem>
  )
}

export default AdminChatMessageBubble
