import { gql } from '@apollo/client'

const GetOrganisationById = gql`
  query GetOrganisationById($orgId: uuid!) {
    org_by_pk(id: $orgId) {
      id
      name
      country_id
      parent
      reader_review_url
      reader_review_short_url
    }
  }
`
export default GetOrganisationById
