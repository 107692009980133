import { ApolloClient, gql } from '@apollo/client'
import {
  NotificationsGetChatMessagesQuery,
  NotificationsGetChatMessagesQueryVariables,
} from '../../../generated/graphql'

const GET_DATA = gql`
  query NotificationsGetChatMessages(
    $where: view_chat_message_with_last_message_bool_exp!
  ) {
    view_chat_message_with_last_message(
      distinct_on: [user_id]
      order_by: [{ user_id: asc }, { chatMessage: { created: desc } }]
      where: $where
    ) {
      chatMessage {
        created
        value

        chatMessageType {
          name
        }

        chatRoom {
          active
          updated
          ownerUser {
            id
            personName
          }
        }

        user {
          id
          person {
            name
            pronouns
          }
          role {
            name
          }
        }
      }
    }
  }
`

export async function getNotifications(
  client: ApolloClient<object>,
  variables: NotificationsGetChatMessagesQueryVariables
) {
  return client.query<
    NotificationsGetChatMessagesQuery,
    NotificationsGetChatMessagesQueryVariables
  >({
    fetchPolicy: 'no-cache',
    query: GET_DATA,
    variables,
  })
}
