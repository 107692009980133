import { gql, QueryResult } from '@apollo/client'
import { useEffect } from 'react'
import {
  useChatButtonGetAdminNumberOfUnreadMessagesQuery,
  useChatButtonGetUserNumberOfUnreadMessagesQuery,
} from '../../../generated/graphql'
import useAuthClaims from '../../../hooks/useAuthClaims'
import { useAppSelector } from '../../../store'
import { CHAT_MESSAGES_REPOLL_INTERVAL } from '../constants/chatSettings'
import { selectChatDrawerOpen } from '../slices/chatDrawerSlice'
import ChatButton from '../views/ChatButton'

const GET_ADMIN_NUMBER_OF_UNREAD_MESSAGES = gql`
  query ChatButtonGetAdminNumberOfUnreadMessages($userId: uuid!) {
    chat_room(
      where: { active: { _eq: true }, owner_user_id: { _eq: $userId } }
    ) {
      user {
        chat_messages_aggregate(
          where: {
            fromUser: { id: { _neq: $userId } }
            chatMessageType: { name: { _in: ["message", "joined"] } }
            unread: { _eq: true }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`

const GET_USER_NUMBER_OF_UNREAD_MESSAGES = gql`
  query ChatButtonGetUserNumberOfUnreadMessages($userId: uuid!) {
    chat_message_aggregate(
      where: {
        from_user_id: { _neq: $userId }
        user_id: { _eq: $userId }
        chatMessageType: { name: { _in: ["message", "joined"] } }
        unread: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

const ChatButtonContainer = () => {
  const { claims, userId } = useAuthClaims()
  const userRole = claims?.role

  const adminNumberOfMessagesQuery =
    useChatButtonGetAdminNumberOfUnreadMessagesQuery({
      skip:
        !userId || !userRole || !['hlo-admin', 'librarian'].includes(userRole),
      variables: {
        userId,
      },
    })
  const userNumberOfMessagesQuery =
    useChatButtonGetUserNumberOfUnreadMessagesQuery({
      skip:
        !userId || !userRole || ['hlo-admin', 'librarian'].includes(userRole),
      variables: {
        userId,
      },
    })

  let query: QueryResult<any, any> | undefined = undefined

  if (userRole) {
    if (['hlo-admin', 'librarian'].includes(userRole)) {
      query = adminNumberOfMessagesQuery
    } else {
      query = userNumberOfMessagesQuery
    }
  }

  const chatDrawerOpen = useAppSelector(selectChatDrawerOpen)

  useEffect(() => {
    if (query) {
      query.startPolling(CHAT_MESSAGES_REPOLL_INTERVAL)
    }

    return () => {
      if (query) {
        query.stopPolling()
      }
    }
  }, [query])

  useEffect(() => {
    if (!chatDrawerOpen && query) {
      query.refetch()
    }
  }, [chatDrawerOpen, query])

  let numberOfMessages

  if (userRole) {
    if (['hlo-admin', 'librarian'].includes(userRole)) {
      numberOfMessages =
        adminNumberOfMessagesQuery.data &&
        Array.isArray(adminNumberOfMessagesQuery.data.chat_room) &&
        adminNumberOfMessagesQuery.data?.chat_room.reduce<number>(
          (previousValue, currentValue) => {
            if (currentValue.user.chat_messages_aggregate.aggregate) {
              return (
                previousValue +
                  currentValue.user.chat_messages_aggregate.aggregate?.count ||
                0
              )
            }

            return previousValue
          },
          0
        )
    } else {
      numberOfMessages =
        userNumberOfMessagesQuery.data?.chat_message_aggregate.aggregate?.count
    }
  }

  return <ChatButton numberOfMessages={numberOfMessages || 0} />
}

export default ChatButtonContainer
