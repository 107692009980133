import * as Sentry from '@sentry/nextjs'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AgoraTokenPayload } from '../lib/agoraTokenHandler'

const getAgoraToken = createAsyncThunk<
  AgoraTokenPayload,
  {
    readingId: string
  }
>('readingRoom/getAgoraToken', async ({ readingId }) => {
  try {
    const data = await fetch(`/api/readingRoom/token/${readingId}`)
    const result = await data.json()

    return result
  } catch (error) {
    Sentry.captureException(error)
    return undefined
  }
})

export default getAgoraToken
