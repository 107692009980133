import { gql } from '@apollo/client'

const InsertReadingRoomChatMessage = gql`
  mutation InsertReadingRoomChatMessage($reading_id: uuid, $message: String) {
    insert_chat_reading_message_one(
      object: { reading_id: $reading_id, message: $message }
    ) {
      id
    }
  }
`

export default InsertReadingRoomChatMessage
