import { gql } from '@apollo/client'

export default gql`
  mutation InsertMessage($userId: uuid!, $value: String!) {
    insert_chat_message_one(
      object: {
        user_id: $userId
        value: $value
        chat_message_type_id: "38596163-10aa-4067-b5b0-116df03069dd"
      }
    ) {
      id
    }
  }
`
