import { Avatar, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import { Box } from '@mui/system'
import COLORS from '../../../../styles/colors'
import { HLO } from '../../../../types/global'
import ChatDate from '../ChatDate'
import ChatMessageListItem from './ChatMessageListItem'
import getHyperlinks from '../../util/getHyperlinks'

export type ChatMessageBubbleProps = {
  date: Date
  fromNameShort: string
  fromUserId: string
  language?: HLO.Language
  toUserId: string
  value: string | undefined
}

const ChatMessageBubble = ({
  date,
  fromNameShort,
  language,
  value,
}: ChatMessageBubbleProps) => {
  return (
    <ChatMessageListItem>
      <ListItemAvatar>
        <Avatar
          sx={{
            background: COLORS.burnedOrange,
          }}
        >
          {fromNameShort}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        sx={{
          background: COLORS.white,
          border: `1px solid ${COLORS.borderGrey}`,
          borderRadius: '8px',
          boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.04)',
          color: COLORS.greyDark,
          fontSize: '0.9rem',
          fontWeight: 500,
          py: '12px',
          px: '16px',
        }}
        primary={
          <Typography variant="inter_h7">
            <span
              dangerouslySetInnerHTML={{ __html: getHyperlinks(value) }}
            ></span>
          </Typography>
        }
        secondary={
          <Box pt="8px">
            <ChatDate date={date} language={language} />
          </Box>
        }
      />
    </ChatMessageListItem>
  )
}

export default ChatMessageBubble
