import { Divider } from '@mui/material'
import React from 'react'
import COLORS from '../styles/colors'

const HeaderDivider = () => {
  return (
    <Divider
      orientation="vertical"
      sx={{ borderColor: COLORS.borderGrey, height: '40px' }}
      variant="middle"
    />
  )
}

export default HeaderDivider
