import { ApolloClient, ApolloQueryResult } from '@apollo/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  GetOrganisationListQuery,
  GetOrganisationListQueryVariables,
} from '../../../generated/graphql'
import { RootState } from '../../../store'
import GetOrganisationList from '../graphql/queries/GetOrganisationList'

export const getOrganisationList = createAsyncThunk<
  ApolloQueryResult<GetOrganisationListQuery>,
  {
    client: ApolloClient<object>
    parentId?: string
    search?: string
  },
  { state: RootState }
>('organisations/getList', async ({ client, parentId, search }, thunkAPI) => {
  let nameWhere: GetOrganisationListQueryVariables['nameWhere'] = {}
  let parentWhere: GetOrganisationListQueryVariables['parentWhere'] = {}

  if (search) {
    nameWhere = {
      _ilike: `%${search}%`,
    }
  }

  if (parentId) {
    parentWhere = {
      _eq: parentId,
    }
  }

  return await client.query<
    GetOrganisationListQuery,
    GetOrganisationListQueryVariables
  >({
    fetchPolicy: 'no-cache',
    query: GetOrganisationList,
    variables: {
      nameWhere,
      parentWhere,
    },
  })
})
