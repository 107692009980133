import { faChevronLeft, faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import { useTranslation } from 'next-i18next'
import COLORS from '../../../styles/colors'
import { HLO } from '../../../types/global'
import { ChatVariant } from '../util/getChatVariant'
import { getIconByRole } from '../util/getIconByRole'

export type ChatHeaderProps = {
  onClose: () => void
  onHide: () => void
  toUserPersonName?: string
  toUserPersonPronouns?: string
  toUserRole?: HLO.Role
  variant: ChatVariant
}

const ChatHeader = ({
  onClose,
  onHide,
  toUserPersonName,
  toUserPersonPronouns,
  toUserRole,
  variant,
}: ChatHeaderProps) => {
  const { t } = useTranslation('Chat')

  let title
  let subTitle

  if (variant === 'admin') {
    title = (
      <Button
        onClick={onHide}
        startIcon={
          <FontAwesomeIcon color={COLORS.white} icon={faChevronLeft} />
        }
        sx={{
          textTransform: 'none',
          '& .MuiButton-startIcon': { paddingRight: '14px' },
        }}
      >
        <Typography color={COLORS.white} variant="inter_formHeader">
          {t('chatHeader.variant.admin.title')}
        </Typography>
      </Button>
    )
    subTitle = (
      <Stack direction="row" gap="20px">
        {toUserRole && (
          <FontAwesomeIcon
            color={COLORS.white}
            style={{ height: '40px' }}
            icon={getIconByRole(toUserRole)}
          />
        )}
        <Stack>
          <Typography
            color={COLORS.white}
            textTransform="capitalize"
            variant="inter_h8"
          >
            {toUserRole}
          </Typography>
          <Typography color={COLORS.white} variant="inter_h6">
            {toUserPersonName}{' '}
            {toUserPersonPronouns && `(${toUserPersonPronouns})`}
          </Typography>
        </Stack>
      </Stack>
    )
  } else if (variant === 'room-list') {
    title = (
      <Typography
        color={COLORS.white}
        textTransform="uppercase"
        variant="chatHeaderTitleUser"
      >
        {t('chatHeader.variant.room-list.title')}
      </Typography>
    )
  } else {
    title = (
      <Typography
        color={COLORS.white}
        textTransform="uppercase"
        variant="chatHeaderTitleUser"
      >
        {t('chatHeader.variant.user.title')}
      </Typography>
    )
  }

  return (
    <Box
      py="20px"
      px="25px"
      sx={{ backgroundColor: COLORS.burnedOrange }}
      borderRadius="8px"
      width="100%"
    >
      <Stack gap="20px">
        <Stack alignItems="center" direction="row">
          <Box flexGrow={1}>{title}</Box>
          <IconButton aria-label="close" onClick={onClose}>
            <FontAwesomeIcon color={COLORS.white} icon={faClose} />
          </IconButton>
        </Stack>
        {subTitle}
      </Stack>
    </Box>
  )
}

export default ChatHeader
