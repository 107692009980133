import { gql } from '@apollo/client'

export default gql`
  mutation UpdateOnboardingProfile(
    $preferredName: String!
    $pronouns: String!
    $userId: uuid!
  ) {
    update_person(
      _set: { name: $preferredName, pronouns: $pronouns }
      where: { users: { id: { _eq: $userId } } }
    ) {
      affected_rows
      returning {
        users {
          id
          role {
            name
          }
        }
      }
    }
  }
`
