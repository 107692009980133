import { ApolloClient, FetchResult } from '@apollo/client'
import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit'
import {
  NotificationsInsertChatMessageMutation,
  NotificationsInsertChatRoomMutation,
  NotificationsInsertChatRoomMutationVariables,
} from '../../../generated/graphql'
import { RootState } from '../../../store'
import { insertChatRoom } from '../services/insertChatRoom'

export type NotificationsState = {
  insert: {
    data?: NotificationsInsertChatMessageMutation['insert_chat_message_one']
    error?: SerializedError
    loading?: boolean
  }
}

const initialState: NotificationsState = { insert: {} }

export const addChatRoom = createAsyncThunk<
  FetchResult<NotificationsInsertChatRoomMutation>,
  {
    client: ApolloClient<object>
    variables: NotificationsInsertChatRoomMutationVariables
  },
  { state: RootState }
>('chatroom/insert', async ({ client, variables }, thunkAPI) => {
  return insertChatRoom(client, variables)
})

export const chatRoomSlice = createSlice({
  name: 'chatRoom',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addChatRoom.pending, (state) => {
        state.insert.error = undefined
        state.insert.loading = true
      })
      .addCase(addChatRoom.fulfilled, (state, { payload }) => {
        state.insert.data = payload.data?.insert_chat_room_one?.user_id
        state.insert.loading = false
      })
      .addCase(addChatRoom.rejected, (state, { error }) => {
        state.insert.error = error
        state.insert.loading = false
      })
  },
})

export const selectInsertChatRoomData = ({
  chatRooms: chatRoom,
}: RootState) => {
  return !!chatRoom.insert.data
}

export const selectInsertChatRoomLoading = ({
  chatRooms: chatRoom,
}: RootState) => {
  return !!chatRoom.insert.loading
}
