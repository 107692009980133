import { gql } from '@apollo/client'

export default gql`
  query GetOnboardingProfile($userId: uuid!) {
    eula(limit: 1, order_by: { version: desc }) {
      id
    }

    user_by_pk(id: $userId) {
      person {
        id
        eula_acceptances {
          eula_id
        }
        name
        pronouns
      }

      role {
        name
      }
    }
  }
`
