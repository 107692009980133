import { ApolloClient, FetchResult } from '@apollo/client'
import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit'
import {
  NotificationsInsertChatMessageMutation,
  NotificationsInsertChatMessageMutationVariables,
} from '../../../generated/graphql'
import { RootState } from '../../../store'
import { insertChatMessage } from '../services/insertChatMessage'

export type ChatMessageSlice = {
  insert: {
    data?: NotificationsInsertChatMessageMutation['insert_chat_message_one']
    error?: SerializedError
    loading?: boolean
  }
}

const initialState: ChatMessageSlice = { insert: {} }

export const sendChatMessage = createAsyncThunk<
  FetchResult<NotificationsInsertChatMessageMutation>,
  {
    client: ApolloClient<object>
    variables: NotificationsInsertChatMessageMutationVariables
  },
  { state: RootState }
>('chatMessage/insertChatMessage', async ({ client, variables }, thunkAPI) => {
  return insertChatMessage(client, variables)
})

export const chatMessageSlice = createSlice({
  name: 'chatMessage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendChatMessage.pending, (state) => {
        state.insert.error = undefined
        state.insert.loading = true
      })
      .addCase(sendChatMessage.fulfilled, (state, { payload }) => {
        state.insert.data = payload.data?.insert_chat_message_one
        state.insert.loading = false
      })
      .addCase(sendChatMessage.rejected, (state, { error }) => {
        state.insert.error = error
        state.insert.loading = false
      })
  },
})

export const selectInsertChatMessageData = ({
  chatMessages: chatMessage,
}: RootState) => {
  return chatMessage.insert.data
}

export const selectInsertChatMessageError = ({
  chatMessages: chatMessage,
}: RootState) => {
  return chatMessage.insert.error
}

export const selectInsertChatMessageLoading = ({
  chatMessages: chatMessage,
}: RootState) => {
  return !!chatMessage.insert.loading
}
