import { Container } from '@mui/material'
import React, { ReactNode } from 'react'
import { CONTAINER_MAX_WIDTH } from '../constants/settings'

export type PageContainerProps = {
  children: ReactNode
  disableGutters?: boolean
}

const PageContainer = ({
  children,
  disableGutters = false,
}: PageContainerProps) => {
  return (
    <Container disableGutters={disableGutters} maxWidth={CONTAINER_MAX_WIDTH}>
      {children}
    </Container>
  )
}

export default PageContainer
