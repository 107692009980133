import { Box, Drawer, Stack, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/system'
import COLORS from '../../../styles/colors'
import { HLO } from '../../../types/global'
import ChatHeader from '../components/ChatHeader'
import { ChatVariant } from '../util/getChatVariant'

export type ChatDrawerProps = {
  children: JSX.Element | JSX.Element[]

  toUser?: {
    id: string
    role: { name: HLO.Role }
    person: {
      name?: string
      pronouns?: string
    }
  }
  open: boolean
  onClose: () => void
  onToggle: () => void
  variant: ChatVariant
  onRoomChange: (userId: string | undefined) => void
}

const ChatDrawer = ({
  children,
  toUser,
  onRoomChange,
  open,
  onClose,
  onToggle,
  variant,
}: ChatDrawerProps) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const handleHide = () => {
    onRoomChange(undefined)
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => onToggle()}
      PaperProps={{
        style: {
          marginTop: isSmall ? 0 : '100px',
          marginRight: isSmall ? 0 : '20px',
          height: '85%',
          maxHeight: '930px',
          left: isSmall ? 0 : 'auto',
          position: isSmall ? 'absolute' : 'fixed',
          width: isSmall ? '100vw' : '460px',
        },
      }}
      BackdropProps={{
        style: {
          width: '100vw',
          height: '100vh',
        },
      }}
    >
      {variant && (
        <Stack direction="row" height="100%" width="100%">
          <Stack
            height="100%"
            width="100%"
            sx={{ background: COLORS.backgroundGreyLight }}
          >
            <ChatHeader
              onClose={onClose}
              onHide={handleHide}
              toUserPersonName={toUser?.person.name}
              toUserPersonPronouns={toUser?.person.pronouns}
              toUserRole={toUser?.role.name}
              variant={variant}
            />
            <Box
              flexGrow={1}
              sx={{
                overflowY: 'hidden',
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
              }}
            >
              {children}
            </Box>
          </Stack>
        </Stack>
      )}
    </Drawer>
  )
}

export default ChatDrawer
