import { gql } from '@apollo/client'

const InsertOrganisation = gql`
  mutation InsertOrganisation(
    $countryId: uuid!
    $name: String!
    $parent: uuid
    $reader_review_url: String!
    $reader_review_short_url: String!
  ) {
    insert_org_one(
      object: {
        country_id: $countryId
        name: $name
        parent: $parent
        reader_review_url: $reader_review_url
        reader_review_short_url: $reader_review_short_url
      }
    ) {
      id
    }
  }
`

export default InsertOrganisation
