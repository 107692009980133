import { ApolloProvider } from '@apollo/client'
import { NextComponentType } from 'next'
import React from 'react'
import useAuthClaims from '../hooks/useAuthClaims'
import getApolloClient from '../util/getApolloClient'

const AuthorizedApolloProvider: NextComponentType = ({ children }) => {
  const { hasuraAccessToken, loading } = useAuthClaims()

  return (
    <ApolloProvider client={getApolloClient(hasuraAccessToken || '')}>
      {children}
    </ApolloProvider>
  )
}

export default AuthorizedApolloProvider
