import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { GetReadingRoomReadingQuery } from '../../../generated/graphql'
import { RootState } from '../../../store'
import { LiveParticipants } from '../hooks/useLiveParticipants'
import { AgoraTokenPayload } from '../lib/agoraTokenHandler'
import getAgoraToken from '../services/getAgoraToken'
import getReadingRoomReading from '../services/getReadingRoomReading'
import sendReadingRoomChatMessage from '../services/sendReadingRoomChatMessage'
import { VirtualBackground } from '../../../components/agoraRTC/useVideoCall'

const actionPrefix = `readingRoom`

export type ReadingRoomClientType = 'presenter' | 'audience' | 'self'

export type ReadingRoomMessage = {
  author: string
  authorUserId: string
  clientType: ReadingRoomClientType
  message: string
  id: string
}

export type ReadingRoomState = {
  agoraToken?: AgoraTokenPayload
  agoraTokenError: boolean
  agoraTokenLoading: boolean
  virtualBackground: VirtualBackground
  cancelled?: boolean
  cancelReviewOpen: boolean
  chatOpen: boolean
  clientType?: ReadingRoomClientType
  completed: boolean
  confirmCancelOpen: boolean
  disclaimerActive: boolean
  handsRaised: {
    [key: string]: {
      lastUpdated: string
    }
  }
  hasAccess?: boolean
  insertMessageLoading: boolean
  messages: ReadingRoomMessage[]
  muted: boolean
  participants: LiveParticipants
  reading?: GetReadingRoomReadingQuery['reading']
  readingError: boolean
  readingLoading: boolean
  raiseHand: boolean
  started: boolean
}

const initialState: ReadingRoomState = {
  agoraTokenError: false,
  agoraTokenLoading: true,
  virtualBackground: 'none',
  cancelReviewOpen: false,
  chatOpen: false,
  clientType: 'audience',
  completed: false,
  confirmCancelOpen: false,
  disclaimerActive: true,
  handsRaised: {},
  insertMessageLoading: false,
  messages: [],
  muted: false,
  participants: {},
  raiseHand: false,
  readingError: false,
  readingLoading: true,
  started: false,
}

export const readingRoomSlice = createSlice({
  name: actionPrefix,
  initialState,
  reducers: {
    setReadingRoomCancelled: (state, { payload }: PayloadAction<boolean>) => {
      state.cancelled = payload
    },
    setReadingRoomDisclaimerActive: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.disclaimerActive = payload
    },
    setReadingRoomCancelReviewOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.cancelReviewOpen = payload
    },
    setReadingRoomChatMessages: (
      state,
      { payload }: PayloadAction<ReadingRoomState['messages']>
    ) => {
      state.messages = payload
    },
    setReadingRoomChatOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.chatOpen = payload
    },
    setReadingRoomClientType: (
      state,
      { payload }: PayloadAction<ReadingRoomClientType>
    ) => {
      state.clientType = payload
    },
    setReadingRoomCompleted: (state, { payload }: PayloadAction<boolean>) => {
      state.completed = payload
    },
    setReadingRoomConfirmCancelOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.confirmCancelOpen = payload
    },
    setReadingRoomHandRaised: (
      state,
      {
        payload: { lastUpdated, userId },
      }: PayloadAction<{ userId: string; lastUpdated: string }>
    ) => {
      state.handsRaised[userId] = { lastUpdated }
    },
    setReadingRoomHasAccess: (state, { payload }: PayloadAction<boolean>) => {
      state.hasAccess = payload
    },
    setReadingRoomMuted: (state, { payload }: PayloadAction<boolean>) => {
      state.muted = payload
    },
    setReadingRoomRaiseHand: (state, { payload }: PayloadAction<boolean>) => {
      state.raiseHand = payload
    },
    setReadingRoomParticipants: (
      state,
      { payload }: PayloadAction<ReadingRoomState['participants']>
    ) => {
      state.participants = payload
    },
    setReadingRoomStarted: (
      state,
      { payload }: PayloadAction<ReadingRoomState['started']>
    ) => {
      state.started = payload
    },

    setReadingRoomVirtualBackground: (
      state,
      { payload }: PayloadAction<VirtualBackground>
    ) => {
      state.virtualBackground = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReadingRoomReading.fulfilled, (state, { payload }) => {
        state.reading = payload.data.reading ? payload.data.reading : undefined
        state.readingError = false
        state.readingLoading = false
      })
      .addCase(getReadingRoomReading.pending, (state) => {
        state.reading = undefined
        state.readingError = false
        state.readingLoading = true
      })
      .addCase(getReadingRoomReading.rejected, (state) => {
        state.reading = undefined
        state.readingError = true
        state.readingLoading = false
      })
      .addCase(sendReadingRoomChatMessage.fulfilled, (state) => {
        if (!state.chatOpen) {
          state.chatOpen = true
        }

        state.insertMessageLoading = false
      })
      .addCase(sendReadingRoomChatMessage.pending, (state) => {
        state.insertMessageLoading = true
      })
      .addCase(sendReadingRoomChatMessage.rejected, (state) => {
        state.insertMessageLoading = false
      })
      .addCase(getAgoraToken.fulfilled, (state, { payload }) => {
        state.agoraToken = payload
        state.agoraTokenError = false
        state.agoraTokenLoading = false
      })
      .addCase(getAgoraToken.pending, (state) => {
        state.agoraTokenError = false
        state.agoraTokenLoading = true
      })
      .addCase(getAgoraToken.rejected, (state) => {
        state.agoraTokenError = true
        state.agoraTokenLoading = false
      })
  },
})

export const {
  setReadingRoomVirtualBackground,
  setReadingRoomCancelled,
  setReadingRoomCancelReviewOpen,
  setReadingRoomChatMessages,
  setReadingRoomChatOpen,
  setReadingRoomClientType,
  setReadingRoomCompleted,
  setReadingRoomConfirmCancelOpen,
  setReadingRoomDisclaimerActive,
  setReadingRoomHandRaised,
  setReadingRoomHasAccess,
  setReadingRoomMuted,
  setReadingRoomRaiseHand,
  setReadingRoomParticipants,
  setReadingRoomStarted,
} = readingRoomSlice.actions

export const selectReadingRoomAgoraToken = ({ readingRoom }: RootState) => {
  return readingRoom.agoraToken
}

export const selectReadingRoomAgoraTokenError = ({
  readingRoom,
}: RootState) => {
  return readingRoom.agoraTokenError
}

export const selectReadingRoomAgoraTokenLoading = ({
  readingRoom,
}: RootState) => {
  return readingRoom.agoraTokenLoading
}

export const selectReadingRoomCancelled = ({ readingRoom }: RootState) => {
  return readingRoom.cancelled
}

export const selectReadingRoomCancelReviewOpen = ({
  readingRoom,
}: RootState) => {
  return readingRoom.cancelReviewOpen
}

export const selectReadingRoomChatOpen = ({ readingRoom }: RootState) => {
  return readingRoom.chatOpen
}

export const selectReadingRoomClientType = ({ readingRoom }: RootState) => {
  return readingRoom.clientType
}

export const selectReadingRoomCompleted = ({ readingRoom }: RootState) => {
  return readingRoom.completed
}

export const selectReadingRoomConfirmCancelOpen = ({
  readingRoom,
}: RootState) => {
  return readingRoom.confirmCancelOpen
}

export const selectReadingRoomDisclaimerActive = ({
  readingRoom,
}: RootState) => {
  return readingRoom.disclaimerActive
}

export const selectReadingRoomRaiseHand = ({ readingRoom }: RootState) => {
  return readingRoom.raiseHand
}

export const selectReadingRoomHandsRaised = ({ readingRoom }: RootState) => {
  return readingRoom.handsRaised
}

export const selectReadingRoomVirtualBackground = ({
  readingRoom,
}: RootState) => {
  return readingRoom.virtualBackground
}

export const selectReadingRoomHasAccess = ({ readingRoom }: RootState) => {
  return readingRoom.hasAccess
}

export const selectReadingRoomInsertMessageLoading = ({
  readingRoom,
}: RootState) => {
  return readingRoom.insertMessageLoading
}

export const selectReadingRoomMessages = ({ readingRoom }: RootState) => {
  return readingRoom.messages
}

export const selectReadingRoomMuted = ({ readingRoom }: RootState) => {
  return readingRoom.muted
}

export const selectReadingRoomParticipants = ({ readingRoom }: RootState) => {
  return readingRoom.participants
}

export const selectReadingRoomReading = ({ readingRoom }: RootState) => {
  return readingRoom.reading
}

export const selectReadingRoomReadingError = ({ readingRoom }: RootState) => {
  return readingRoom.readingError
}

export const selectReadingRoomReadingLoading = ({ readingRoom }: RootState) => {
  return readingRoom.readingLoading
}

export const selectReadingRoomStarted = ({ readingRoom }: RootState) => {
  return readingRoom.started
}
