import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'next-i18next'
import { GetChatMessagesSubscription } from '../../../../generated/graphql'
import COLORS from '../../../../styles/colors'
import { HLO } from '../../../../types/global'
import { getInitials } from '../../../../util/getInitials'
import { ChatVariant } from '../../util/getChatVariant'
import getFormatedChatMessageEvent from '../../util/getFormatedChatMessageEvent'
import AdminChatMessageBubble from './AdminChatMessageBubble'
import ChatMessageBubble from './ChatMessageBubble'
import ChatMessageEvent from './ChatMessageEvent'
import { ChatMessageListItemProps } from './ChatMessageListItem'

export type ChatMessageType =
  | 'cancel_all_readings'
  | 'did_not_join_reading'
  | 'joined'
  | 'kicked'
  | 'message'
  | 'resolved'

export type ChatMessageProps = {
  language?: HLO.Language
  message: GetChatMessagesSubscription['chatMessages'][0]
  ref?: ChatMessageListItemProps['ref']
  variant: ChatVariant
}

const getIcon = (type: ChatMessageType) => {
  if (type === 'cancel_all_readings') {
    return (
      <FontAwesomeIcon
        color={COLORS.burnedOrange}
        icon={faExclamation}
        width="24px"
      />
    )
  }

  return undefined
}

const ChatMessage = ({ language, message, variant }: ChatMessageProps) => {
  const {
    chatMessageType,
    created,
    fromPersonName,
    fromUser,
    from_user_id: fromUserId,
    user_id: userId,
    value,
  } = message
  const { t } = useTranslation('Chat')

  const date = new Date(created)
  const fromUserRole = fromUser?.role.name || ''
  const type = chatMessageType.name

  if (['cancel_all_readings', 'joined', 'resolved'].includes(type)) {
    const icon = getIcon(type as ChatMessageType)

    return (
      <ChatMessageEvent
        icon={icon}
        value={getFormatedChatMessageEvent(
          t(`events.${type}`),
          fromPersonName || ''
        )}
      />
    )
  }

  if (variant === 'user') {
    return (
      <ChatMessageBubble
        date={date}
        fromNameShort={getInitials(fromPersonName || '')}
        fromUserId={fromUserId}
        language={language}
        toUserId={userId}
        value={value || undefined}
      />
    )
  }

  return (
    <AdminChatMessageBubble
      date={date}
      fromName={fromPersonName || ''}
      fromUserId={fromUserId}
      fromUserRole={fromUserRole as HLO.Role}
      language={language}
      toUserId={userId}
      value={
        type === 'message'
          ? value || undefined
          : getFormatedChatMessageEvent(
              t(`events.${type}`),
              fromPersonName || ''
            )
      }
    />
  )
}

export default ChatMessage
