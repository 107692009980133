import styled from '@emotion/styled'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Fab } from '@mui/material'
import Link from 'next/link'
import COLORS from '../../../styles/colors'

export type NotificationButtonProps = {
  numberOfNotifications?: number
}

const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    color: COLORS.white,
    backgroundColor: COLORS.burnedOrange,
  },
})

const NotificationButton = ({
  numberOfNotifications,
}: NotificationButtonProps) => {
  return (
    <StyledBadge badgeContent={numberOfNotifications} color="primary" max={9}>
      <Link href="/admin/notifications" passHref>
        <Fab
          sx={{
            backgroundColor: COLORS.white,
            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.04)',
            border: `1px ${COLORS.greyDark} solid`,
            height: '40px',
            width: '40px',
          }}
        >
          <FontAwesomeIcon color={COLORS.greyDark} icon={faBell} />
        </Fab>
      </Link>
    </StyledBadge>
  )
}

export default NotificationButton
