import { gql } from '@apollo/client'

export default gql`
  mutation InsertOnboardingEulaAccept($eulaId: uuid!, $personId: uuid!) {
    insert_eula_acceptance_one(
      object: { eula_id: $eulaId, person_id: $personId }
      on_conflict: { constraint: eula_acceptance_pk, update_columns: accepted }
    ) {
      eula_id
    }
  }
`
