import { gql } from '@apollo/client'

const UpdateOrganisationById = gql`
  mutation UpdateOrganisationById(
    $countryId: uuid!
    $name: String!
    $orgId: uuid!
    $parent: uuid
    $reader_review_url: String!
    $reader_review_short_url: String!
  ) {
    update_org_by_pk(
      _set: {
        country_id: $countryId
        name: $name
        parent: $parent
        reader_review_url: $reader_review_url
        reader_review_short_url: $reader_review_short_url
      }
      pk_columns: { id: $orgId }
    ) {
      id
    }
  }
`

export default UpdateOrganisationById
