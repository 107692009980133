import { ApolloClient, gql } from '@apollo/client'
import {
  NotificationsInsertChatMessageMutation,
  NotificationsInsertChatMessageMutationVariables,
} from '../../../generated/graphql'

const INSERT_CHAT_ROOM = gql`
  mutation NotificationsInsertChatMessage(
    $messageTypeId: uuid!
    $userId: uuid!
  ) {
    insert_chat_message_one(
      object: { chat_message_type_id: $messageTypeId, user_id: $userId }
    ) {
      id
    }
  }
`

export async function insertChatMessage(
  client: ApolloClient<object>,
  variables: NotificationsInsertChatMessageMutationVariables
) {
  return client.mutate<
    NotificationsInsertChatMessageMutation,
    NotificationsInsertChatMessageMutationVariables
  >({
    fetchPolicy: 'no-cache',
    mutation: INSERT_CHAT_ROOM,
    variables,
  })
}
