import { gql } from '@apollo/client'
import { CORE_READING_FIELDS } from '../../../../graphql/fragments/Reading'

const GetReadingRoomReading = gql`
  ${CORE_READING_FIELDS}
  query GetReadingRoomReading($id: uuid!) {
    reading: reading_by_pk(id: $id) {
      ...CoreReadingFields
      cancel_reviews {
        user_id
      }
      reviews {
        user_id
        id
      }
    }
  }
`

export default GetReadingRoomReading
