import { gql } from '@apollo/client'
import { useEffect } from 'react'
import { useGetNotificationCountQuery } from '../../../generated/graphql'
import useAuthClaims from '../../../hooks/useAuthClaims'
import { CHAT_MESSAGES_REPOLL_INTERVAL } from '../constants/chatSettings'
import NotificationButton from '../views/NotificationButton'

const GET_NOTIFICATION_COUNT = gql`
  query GetNotificationCount {
    view_chat_message_with_last_message_aggregate(
      distinct_on: [user_id]
      order_by: [{ user_id: asc }, { chatMessage: { created: desc } }]
      where: {
        chatMessage: {
          chatMessageType: {
            name: {
              _in: [
                "message"
                "cancel_all_readings"
                "did_not_join_reading"
                "kick"
                "joined"
                "resolved"
              ]
            }
          }
        }
        lastChatMessage: { chatMessageType: { name: { _neq: "resolved" } } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

const NotificationButtonContainer = () => {
  const { userId, claims } = useAuthClaims()
  const userRole = claims?.role
  const { data, startPolling, stopPolling } = useGetNotificationCountQuery({
    skip:
      !userId || !userRole || !['hlo-admin', 'librarian'].includes(userRole),
  })

  useEffect(() => {
    startPolling(CHAT_MESSAGES_REPOLL_INTERVAL)

    return () => {
      stopPolling()
    }
  }, [])

  const numberOfNotifications =
    data?.view_chat_message_with_last_message_aggregate.aggregate?.count || 0

  return <NotificationButton numberOfNotifications={numberOfNotifications} />
}

export default NotificationButtonContainer
