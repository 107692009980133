import { ApolloClient, FetchResult } from '@apollo/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  UpdateOnboardingProfileMutation,
  UpdateOnboardingProfileMutationVariables,
} from '../../../generated/graphql'
import { RootState } from '../../../store'
import UpdateOnboardingProfile from '../graphql/mutations/UpdateOnboardingProfile'

export const updateOnboardingProfile = createAsyncThunk<
  FetchResult<UpdateOnboardingProfileMutation>,
  {
    client: ApolloClient<object>
    variables: UpdateOnboardingProfileMutationVariables
  },
  { state: RootState }
>('onboardingProfile/update', async ({ client, variables }, thunkAPI) => {
  return client.mutate<
    UpdateOnboardingProfileMutation,
    UpdateOnboardingProfileMutationVariables
  >({
    fetchPolicy: 'no-cache',
    mutation: UpdateOnboardingProfile,
    variables,
  })
})
