import styled from '@emotion/styled'
import { faMessage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Fab } from '@mui/material'
import { useEffect } from 'react'
import { useAppDispatch } from '../../../store'
import COLORS from '../../../styles/colors'
import { toggleChat } from '../slices/chatDrawerSlice'

export type ChatButtonProps = { numberOfMessages?: number }

let hideAccessibilityOnNoMessages: boolean = false

const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    color: COLORS.white,
    backgroundColor: COLORS.burnedOrange,
  },
})

const ChatButton = ({ numberOfMessages = 0 }: ChatButtonProps) => {
  const dispatch = useAppDispatch()
  numberOfMessages === 0
    ? (hideAccessibilityOnNoMessages = true)
    : (hideAccessibilityOnNoMessages = false)

  useEffect(() => {
    hideSpanElement()
  }, [])

  let hideSpanElement = () => {
    return document
      .getElementsByClassName('MuiBadge-overlapRectangular')[0]
      .setAttribute('aria-hidden', `${hideAccessibilityOnNoMessages}`)
  }

  return (
    <>
      <StyledBadge badgeContent={numberOfMessages} color="primary" max={9}>
        <Fab
          aria-label="Chat"
          onClick={() => dispatch(toggleChat())}
          sx={{
            backgroundColor: COLORS.white,
            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.04)',
            border: `1px ${COLORS.greyDark} solid`,
            height: '40px',
            width: '40px',
          }}
        >
          <FontAwesomeIcon
            color={COLORS.greyDark}
            icon={faMessage}
            aria-hidden={hideAccessibilityOnNoMessages}
          />
        </Fab>
      </StyledBadge>
    </>
  )
}

export default ChatButton
