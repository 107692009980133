import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { grey, red } from '@mui/material/colors'
import React from 'react'
import { TEST_ID_FEEDBACK } from '../constants/testIds'

export type FeedbackProps = {
  type: 'loading' | 'error'
  text?: string | JSX.Element | JSX.Element[]
  additionalContent?: React.ReactNode
}

const Feedback = ({
  type,
  text = type === 'loading' ? 'Loading...' : 'Error',
  additionalContent,
}: FeedbackProps) => {
  return (
    <Stack
      data-testid={TEST_ID_FEEDBACK}
      direction="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="2rem"
    >
      {type === 'loading' && <CircularProgress color="error" />}

      <Typography
        variant="h6"
        color={type === 'loading' ? grey[900] : red[500]}
      >
        {text}
      </Typography>

      {additionalContent}
    </Stack>
  )
}

export default Feedback
