import { ApolloClient, ApolloQueryResult } from '@apollo/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  GetOnboardingProfileQuery,
  GetOnboardingProfileQueryVariables,
  GetOnboardingReadingPlanQuery,
  GetOnboardingReadingPlanQueryVariables,
} from '../../../generated/graphql'
import { RootState } from '../../../store'
import GetOnboardingProfile from '../graphql/queries/GetOnboardingProfile'
import GetOnboardingReadingPlan from '../graphql/queries/GetOnboardingReadingPlan'

export const fetchOnboardingProfile = createAsyncThunk<
  {
    user: ApolloQueryResult<GetOnboardingProfileQuery>
    readingPlan?: ApolloQueryResult<GetOnboardingReadingPlanQuery>
  },
  {
    client: ApolloClient<object>
    variables: GetOnboardingProfileQueryVariables
  },
  { state: RootState }
>('onboardingProfile/get', async ({ client, variables }, thunkAPI) => {
  const user = await client.query<
    GetOnboardingProfileQuery,
    GetOnboardingProfileQueryVariables
  >({
    fetchPolicy: 'no-cache',
    query: GetOnboardingProfile,
    variables,
  })

  let readingPlan

  if (
    ['reader', 'superreader'].includes(user.data.user_by_pk?.role.name || '')
  ) {
    readingPlan = await client.query<
      GetOnboardingReadingPlanQuery,
      GetOnboardingReadingPlanQueryVariables
    >({
      fetchPolicy: 'no-cache',
      query: GetOnboardingReadingPlan,
      variables,
    })
  }

  return {
    user,
    readingPlan,
  }
})
