import { ApolloClient, ApolloQueryResult } from '@apollo/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  GetReadingRoomReadingQuery,
  GetReadingRoomReadingQueryVariables,
} from '../../../generated/graphql'
import { RootState } from '../../../store'
import GetReadingRoomReading from '../graphql/queries/GetReadingRoomReading'

const getReadingRoomReading = createAsyncThunk<
  ApolloQueryResult<GetReadingRoomReadingQuery>,
  {
    apolloClient: ApolloClient<any>
    readingId: string
    userId: string
  },
  { state: RootState }
>('readingRoom/getReading', async ({ apolloClient, readingId: id }) => {
  return apolloClient.query<
    GetReadingRoomReadingQuery,
    GetReadingRoomReadingQueryVariables
  >({
    fetchPolicy: 'no-cache',
    query: GetReadingRoomReading,
    variables: {
      id,
    },
  })
})

export default getReadingRoomReading
