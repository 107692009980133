import { gql } from '@apollo/client'

const GetReadingPlanByUserId = gql`
  query GetReadingPlanByUserId($userId: uuid!) {
    readingplan(where: { user_id: { _eq: $userId } }) {
      activated
      created
      id
      initialreadings
      remainingreadings
      updated
      updatedBy {
        personName
      }
      valid_to

      pillarconstraints {
        pillar {
          id
          title
        }
      }

      creator {
        personName
      }
    }
  }
`

export default GetReadingPlanByUserId
