import { createAction, createSlice } from '@reduxjs/toolkit'
import {
  GetOrganisationByIdQuery,
  GetOrganisationListQuery,
} from '../../../generated/graphql'
import { RootState } from '../../../store'
import { getOrganisationList } from '../services/getOrganisationList'
import { getOrganisationById } from '../services/getOrganisationById'
import { updateOrganisationById } from '../services/updateOrganisationById'
import { insertOrganisation } from '../services/insertOrganisation'

export type OrganisationListState = {
  error?: boolean
  list?: GetOrganisationListQuery['org']
  insertLoading?: boolean
  loading?: boolean
  search: string
  parent?: string
  selected?: GetOrganisationByIdQuery['org_by_pk']
  success?: boolean
}

const initialState: OrganisationListState = { search: '' }

export const setOrganisationListSearch = createAction<string>(
  'organisationList/setSearch'
)

export const setOrganisationListParent = createAction<string>(
  'organisationList/setParent'
)

export const organisationListSlice = createSlice({
  name: 'organisationList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrganisationList.pending, (state) => {
        state.error = undefined
        state.loading = true
      })
      .addCase(getOrganisationList.fulfilled, (state, { payload }) => {
        state.error = undefined
        state.list = payload.data.org
        state.loading = false
      })
      .addCase(getOrganisationList.rejected, (state, { error }) => {
        state.error = true
        state.loading = false
      })
      .addCase(getOrganisationById.pending, (state) => {
        state.error = undefined
        state.loading = true
        state.success = undefined
      })
      .addCase(getOrganisationById.fulfilled, (state, { payload }) => {
        state.error = undefined
        state.loading = false
        state.selected = payload.data.org_by_pk
      })
      .addCase(getOrganisationById.rejected, (state, { error }) => {
        state.error = true
        state.loading = false
      })
      .addCase(updateOrganisationById.pending, (state) => {
        state.error = undefined
        state.insertLoading = true
        state.success = undefined
      })
      .addCase(updateOrganisationById.fulfilled, (state, { payload }) => {
        state.error = undefined
        state.insertLoading = false
        state.success = true
      })
      .addCase(updateOrganisationById.rejected, (state, { error }) => {
        state.error = true
        state.insertLoading = false
        state.success = false
      })
      .addCase(insertOrganisation.pending, (state) => {
        state.error = undefined
        state.insertLoading = true
        state.success = undefined
      })
      .addCase(insertOrganisation.fulfilled, (state, { payload }) => {
        state.error = undefined
        state.insertLoading = false
        state.success = true
      })
      .addCase(insertOrganisation.rejected, (state, { error }) => {
        state.error = true
        state.insertLoading = false
        state.success = false
      })
      .addCase(setOrganisationListParent, (state, { payload }) => {
        state.parent = payload
      })
      .addCase(setOrganisationListSearch, (state, { payload }) => {
        state.search = payload
      })
  },
})

export const selectOrganisationListError = ({
  organisationList,
}: RootState) => {
  return !!organisationList.error
}

export const selectOrganisationList = ({ organisationList }: RootState) => {
  return organisationList.list || []
}

export const selectOrganisationListInsertLoading = ({
  organisationList,
}: RootState) => {
  return !!organisationList.insertLoading
}

export const selectOrganisationListLoading = ({
  organisationList,
}: RootState) => {
  return !!organisationList.loading
}

export const selectOrganisationListParent = ({
  organisationList,
}: RootState) => {
  return organisationList.parent
}

export const selectOrganisationListSelected = ({
  organisationList,
}: RootState) => {
  return organisationList.selected
}

export const selectOrganisationListSearch = ({
  organisationList,
}: RootState) => {
  return organisationList.search
}

export const selectOrganisationListSuccess = ({
  organisationList,
}: RootState) => {
  return !!organisationList.success
}
