import { grey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import React from 'react'
import COLORS from './styles/colors'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    garage_h1: React.CSSProperties
    garage_h2: React.CSSProperties
    garage_h3: React.CSSProperties
    garage_h4: React.CSSProperties
    garage_h5: React.CSSProperties
    inter_h1: React.CSSProperties
    inter_h2: React.CSSProperties
    inter_h3: React.CSSProperties
    inter_h4: React.CSSProperties
    inter_h5_bold: React.CSSProperties
    inter_h5_semibold: React.CSSProperties
    inter_h6: React.CSSProperties
    inter_h7: React.CSSProperties
    inter_h8: React.CSSProperties
    inter_bodyText: React.CSSProperties
    inter_blockQuote: React.CSSProperties
    inter_formHeader: React.CSSProperties
    inter_inputText: React.CSSProperties
    menuItem: React.CSSProperties
    footerTitle: React.CSSProperties
    inter_smallText: React.CSSProperties
    readingDate: React.CSSProperties
    readingTime: React.CSSProperties
    pillarTitle: React.CSSProperties
    topicName: React.CSSProperties
    seatsAvailable: React.CSSProperties
    readingCardButton: React.CSSProperties
    readingCardMenuItem: React.CSSProperties
    bookshelfTitle: React.CSSProperties
    bookShelfDescription: React.CSSProperties
    loginTitle: React.CSSProperties
    readingPageMoreTitle: React.CSSProperties
    readingPageReadingTimeSmall: React.CSSProperties
    readingPageReadingTime: React.CSSProperties
    readingPageSeatsAvailableSmall: React.CSSProperties
    readingPageSeatsAvailable: React.CSSProperties
    readingPageQuote: React.CSSProperties
    paperTitle: React.CSSProperties
    libraryCardTitle: React.CSSProperties
    libraryCardTitlePostFix: React.CSSProperties
    libraryCardName: React.CSSProperties
    libraryCardEmail: React.CSSProperties
    libraryCardReaderSince: React.CSSProperties
    readingPlanBody: React.CSSProperties
    readingPlanBodyBold: React.CSSProperties
    reviewTitle: React.CSSProperties
    reviewSubTitle: React.CSSProperties
    reviewBody: React.CSSProperties
    quoteCardText: React.CSSProperties
    quoteCardStrikeThroughText: React.CSSProperties
    quoteCardStrikeThroughLine: React.CSSProperties
    carousellPillarTitle: React.CSSProperties
    carousellTopicTitle: React.CSSProperties
    createReadingTitle: React.CSSProperties
    createReadingError: React.CSSProperties
    readingListPillar: React.CSSProperties
    bookReadingListItemReviewTitle: React.CSSProperties
    AdminSubnavActive: React.CSSProperties
    AdminSubnavInactive: React.CSSProperties
    chatHeaderTitleUser: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    garage_h1?: React.CSSProperties
    garage_h2?: React.CSSProperties
    garage_h3?: React.CSSProperties
    garage_h4?: React.CSSProperties
    garage_h5?: React.CSSProperties
    inter_h1?: React.CSSProperties
    inter_h2?: React.CSSProperties
    inter_h3?: React.CSSProperties
    inter_h4?: React.CSSProperties
    inter_h5_bold?: React.CSSProperties
    inter_h5_semibold?: React.CSSProperties
    inter_h6?: React.CSSProperties
    inter_h7?: React.CSSProperties
    inter_h8?: React.CSSProperties
    inter_bodyText?: React.CSSProperties
    inter_blockQuote?: React.CSSProperties
    inter_formHeader?: React.CSSProperties
    inter_inputText?: React.CSSProperties
    menuItem?: React.CSSProperties
    footerTitle?: React.CSSProperties
    inter_smallText?: React.CSSProperties
    readingDate?: React.CSSProperties
    readingTime?: React.CSSProperties
    pillarTitle?: React.CSSProperties
    topicName?: React.CSSProperties
    seatsAvailable?: React.CSSProperties
    readingCardButton?: React.CSSProperties
    readingCardMenuItem?: React.CSSProperties
    bookshelfTitle?: React.CSSProperties
    bookShelfDescription?: React.CSSProperties
    loginTitle?: React.CSSProperties
    readingPageMoreTitle?: React.CSSProperties
    readingPageReadingTimeSmall?: React.CSSProperties
    readingPageReadingTime?: React.CSSProperties
    readingPageSeatsAvailableSmall?: React.CSSProperties
    readingPageSeatsAvailable?: React.CSSProperties
    readingPageQuote?: React.CSSProperties
    paperTitle?: React.CSSProperties
    libraryCardTitle?: React.CSSProperties
    libraryCardTitlePostFix?: React.CSSProperties
    libraryCardName: React.CSSProperties
    libraryCardEmail: React.CSSProperties
    libraryCardReaderSince: React.CSSProperties
    readingPlanBody?: React.CSSProperties
    readingPlanBodyBold?: React.CSSProperties
    reviewTitle?: React.CSSProperties
    reviewSubTitle?: React.CSSProperties
    reviewBody?: React.CSSProperties
    quoteCardText?: React.CSSProperties
    quoteCardStrikeThroughText?: React.CSSProperties
    quoteCardStrikeThroughLine?: React.CSSProperties
    carouselTopicTitle?: React.CSSProperties
    bookFaqImageOverlay?: React.CSSProperties
    stillHaveQuestionsTitle?: React.CSSProperties
    bookFaqBody?: React.CSSProperties
    bookFaqBodyLight?: React.CSSProperties
    createReadingTitle?: React.CSSProperties
    createReadingError?: React.CSSProperties
    readingListPillar?: React.CSSProperties
    bookReadingListItemReviewTitle?: React.CSSProperties
    AdminSubnavActive?: React.CSSProperties
    AdminSubnavInactive?: React.CSSProperties
    chatHeaderTitleUser?: React.CSSProperties
  }

  interface ButtonPropsVariants {
    attendButton: React.CSSProperties
    attendButtonSecondary: React.CSSProperties
    attendingButton: React.CSSProperties
    joinLobbyButton: React.CSSProperties
    loadMoreButton: React.CSSProperties
    transparentButton: React.CSSProperties
  }

  interface ButtonPropsVariantOptions {
    attendButton?: React.CSSProperties
    attendButtonSecondary?: React.CSSProperties
    attendingButton?: React.CSSProperties
    joinLobbyButton?: React.CSSProperties
    loadMoreButton: React.CSSProperties
    transparentButton?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    garage_h1: true
    garage_h2: true
    garage_h3: true
    garage_h4: true
    garage_h5: true
    inter_h1: true
    inter_h2: true
    inter_h3: true
    inter_h4: true
    inter_h5_bold: true
    inter_h5_semibold: true
    inter_h6: true
    inter_h7: true
    inter_h8: true
    inter_bodyText: true
    inter_blockQuote: true
    inter_formHeader: true
    inter_inputText: true
    menuItem: true
    footerTitle: true
    inter_smallText: true
    readingDate: true
    readingTime: true
    pillarTitle: true
    topicName: true
    seatsAvailable: true
    readingCardButton: true
    readingCardMenuItem: true
    bookshelfTitle: true
    bookShelfDescription: true
    loginTitle: true
    readingPageMoreTitle: true
    readingPageReadingTimeSmall: true
    readingPageReadingTime: true
    readingPageSeatsAvailableSmall: true
    readingPageSeatsAvailable: true
    readingPageQuote: true
    paperTitle: true
    libraryCardTitle: true
    libraryCardTitlePostFix: true
    libraryCardName: true
    libraryCardEmail: true
    libraryCardReaderSince: true
    readingPlanBody: true
    readingPlanBodyBold: true
    reviewTitle: true
    reviewSubTitle: true
    reviewBody: true
    quoteCardText: true
    quoteCardStrikeThroughText: true
    quoteCardStrikeThroughLine: true
    carouselTopicTitle: true
    bookFaqImageOverlay: true
    stillHaveQuestionsTitle: true
    bookFaqBody: true
    bookFaqBodyLight: true
    createReadingTitle: true
    createReadingError: true
    readingListPillar: true
    bookReadingListItemReviewTitle: true
    AdminSubnavActive: true
    AdminSubnavInactive: true
    chatHeaderTitleUser: true
    // @TODO: Outcomment this when old styling is completely removed
    /*
    h1: false
    h2: false
    h3: false
    h4: false
    h5: false
    h6: false
    subtitle1: false
    subtitle2: false
    body1: false
    body2: false
    button: false
    caption: false
    overline: false
    */
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    attendButton: true
    attendButtonSecondary: true
    attendingButton: true
    joinLobbyButton: true
    loadMoreButton: true
    transparentButton: true
  }
  interface ReadingActionButtonPropsVariantOverrides {
    attendButton: true
    attendButtonSecondary: true
    attendingButton: true
    joinLobbyButton: true
    loadMoreButton: true
    transparentButton: true
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 1064,
      lg: 1240,
      xl: 1680,
    },
  },
  components: {
    MuiScopedCssBaseline: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          minHeight: '100vh',
          margin: 0,
          padding: 0,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          color: '#000',
          boxShadow: `0px 2px 2px rgba(0, 0, 0, 0.04), inset 0px -1px 0px ${COLORS.borderGrey}`,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Inter", serif',
        },
      },
      variants: [
        {
          props: { color: 'secondary', variant: 'contained' },
          style: {
            fontFamily: 'Inter',
            fontWeight: '600',
            fontSize: '18px',
            textTransform: 'none',

            backgroundColor: COLORS.burnedOrange,
            color: 'white',
            '&:hover': {
              backgroundColor: COLORS.burnedOrange,
              color: 'white',
            },
            '&:active': {
              backgroundColor: COLORS.burnedOrange,
              color: 'white',
            },
          },
        },
        {
          props: { color: 'secondary', variant: 'outlined' },
          style: {
            backgroundColor: 'transparent',
            color: grey[700],
            borderColor: grey[700],
            '&:hover': {
              backgroundColor: grey[200],
              borderColor: grey[700],
            },
            '&:active': {
              backgroundColor: grey[200],
              borderColor: grey[700],
            },
          },
        },
        {
          props: { variant: 'transparentButton' },
          style: {
            background: 'transparent',
            color: '#fff',
            border: '1px solid #fff',
            borderRadius: '8px',
            '& .MuiButton-startIcon': {
              color: '#fff',
            },
            '&:hover': {
              background: `linear-gradient(0deg ,rgba(22,22,29,0.05) , rgba(22,22,29,0.05)),${COLORS.greyDark}`,
            },
          },
        },
        {
          props: { variant: 'attendButton' },
          style: {
            '&:hover': {
              background:
                'linear-gradient(0deg ,rgba(22,22,29,0.05) , rgba(22,22,29,0.05)),#FFFFFF',
              border: `1px solid ${COLORS.greyDark}`,
            },
          },
        },
        {
          props: { variant: 'attendButtonSecondary' },
          style: {
            color: '#fff',
            '& .MuiButton-startIcon': {
              color: '#fff',
            },
            '&:hover': {
              background: COLORS.burnedOrange,
              border: `1px solid ${COLORS.greyDark}`,
            },
          },
        },
        {
          props: { variant: 'attendingButton' },
          style: {
            '&:hover': {
              background:
                'linear-gradient(0deg,rgba(214,57,29,0.05),rgba(214,57,29,0.05)),#FFFFFF',
            },
          },
        },
        {
          props: { variant: 'joinLobbyButton' },
          style: {
            background: COLORS.burnedOrange,
            color: '#FFFFFF',
            borderRadius: '8px',
            whiteSpace: 'nowrap',
            '&:hover': {
              background:
                'linear-gradient(0deg,rgba(22,22,29,0.1),rgba(22,22,29,0.1)),#D6391D',
            },
          },
        },
        {
          props: { variant: 'loadMoreButton' },
          style: {
            background: '#fff',
            border: `1px solid ${COLORS.borderGrey}`,
            borderRadius: '8px',
            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.04)',
            color: COLORS.greyDark,
            fontSize: '20px',
            fontWeight: 600,
            height: '40px',
            lineHeight: '20px',
            px: '14px',
            textTransform: 'none',
            '&:hover': {
              background:
                'linear-gradient(0deg, rgba(22, 22, 29, 0.05), rgba(22, 22, 29, 0.05)), #FFFFFF;',
              boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.04)',
            },
            '& .MuiButton-startIcon': {
              marginRight: '10px',
            },
          },
        },
        {
          props: {
            variant: 'loadMoreButton',
            color: 'primary',
          },
          style: {
            background:
              'linear-gradient(0deg, rgba(22, 22, 29, 0.05), rgba(22, 22, 29, 0.05)), #FFFFFF;',
            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.04)',
          },
        },
      ],
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: grey[800],
          borderRadius: '8px',
          boxSizing: 'border-box',
          overflow: 'hidden',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: COLORS.burnedOrange,
          },
        },
        colorPrimary: {
          color: COLORS.borderGrey,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          background: '#fff',
          border: `1px solid ${COLORS.borderGrey}`,
          borderRadius: '8px',
          fontFamily: 'Inter',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 400,
          height: '40px',
          lineHeight: '120%',
          '& .MuiChip-icon': {
            color: COLORS.burnedOrange,
          },
          '& .MuiChip-deleteIconMedium': {
            display: 'none',
            width: '10px',
          },

          '&:hover': {
            backgroundColor: '#fff',
            boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.16)',

            '& .MuiChip-deleteIcon': {
              display: 'block',
            },
          },
        },
      },
      variants: [
        {
          props: { color: 'primary' },
          style: {
            backgroundColor: COLORS.burnedOrange,
            color: '#fff',
            fontWeight: 500,

            '& .MuiChip-icon': {
              color: '#fff',
            },
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: COLORS.borderGrey,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.backgroundGreyLight,
          '& .MuiLinearProgress-colorPrimary': {
            backgroundColor: COLORS.burnedOrange,
          },
          '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: COLORS.burnedOrange,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: COLORS.grey,
          fontFamily: 'garage-gothic',
          fontSize: '40px',
          fontWeight: 700,
          letterSpacing: '-0.02em',
          lineHeight: '100%',
          '&.Mui-selected': {
            color: COLORS.greyDark,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '.MuiTabs-indicator': {
            backgroundColor: COLORS.burnedOrange,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            backgroundColor: COLORS.white,
            border: `1px solid ${COLORS.borderGrey}`,
            boxShadow: '2px 2px 2px rgba(0,0,0,0.04)',
            borderRadius: '8px',

            '&:not(textarea)': {
              height: '44px',
            },
          },
          '& .MuiInputLabel-root': {
            all: 'unset',
            paddingBottom: '0.5rem',
            fontWeight: 'bold',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            display: 'none',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: '"Inter",serif',
    // @TODO: Remove h1-h3 (old styles)
    h1: {
      fontFamily: '"Inter", serif',
      fontSize: '2rem',
      fontWeight: 700,
    },
    h2: {
      fontFamily: '"Inter", serif',
    },
    h3: { fontFamily: '"Inter", serif' },

    // Variants according to Figma, excluding color and letterspacing when unset
    garage_h1: {
      fontFamily: 'garage-gothic',
      fontWeight: 700,
      fontSize: '6.25rem',
      lineHeight: '100%',
      letterSpacing: '-0.02em',
      textTransform: 'uppercase',
    },
    garage_h2: {
      fontFamily: 'garage-gothic',
      fontWeight: 700,
      fontSize: '5.25rem',
      lineHeight: '100%',
      letterSpacing: '-0.02em',
      textTransform: 'uppercase',
    },
    garage_h3: {
      fontFamily: 'garage-gothic',
      fontWeight: 700,
      fontSize: '3.125rem',
      lineHeight: '100%',
      letterSpacing: '-0.02em',
      textTransform: 'uppercase',
    },
    garage_h4: {
      fontFamily: 'garage-gothic',
      fontWeight: 700,
      fontSize: '2.5rem',
      lineHeight: '100%',
      letterSpacing: '-0.02em',
      textTransform: 'uppercase',
    },
    garage_h5: {
      fontFamily: 'garage-gothic',
      fontWeight: 700,
      fontSize: '2.25rem',
      lineHeight: '100%',
      letterSpacing: '-0.02em',
      textTransform: 'uppercase',
    },
    inter_h1: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '1.75rem',
      lineHeight: '120%',
    },
    inter_h2: {
      fontFamily: 'Inter, sans-serif',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '1.5rem',
      lineHeight: '120%',
    },
    inter_h3: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: '120%',
    },
    inter_h4: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.375rem',
      lineHeight: '120%',
    },
    inter_h5_bold: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.25rem',
      lineHeight: '100%',
    },
    inter_h5_semibold: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: '100%',
      letterSpacing: '-0.02em',
    },
    inter_h6: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.125rem',
      lineHeight: '120%',
    },
    inter_h7: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '1.125rem',
      lineHeight: '120%',
    },
    inter_h8: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '140%',
    },
    inter_blockQuote: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.125rem',
      lineHeight: '145%',
    },
    inter_bodyText: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1.125rem',
      lineHeight: '145%',
    },
    inter_inputText: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '140%',
      color: COLORS.greyDark,
    },
    inter_formHeader: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.25rem',
      lineHeight: '120%',
      color: COLORS.greyDark,
    },
    // @TODO Look through below and check if some are still needed, and if they are used multiple times, so that we can make it a variant
    inter_smallText: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '130%',
    },
    menuItem: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '130%',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      letterSpacing: '-0.02em',
    },
    footerTitle: {
      fontFamily: 'garage-gothic',
      fontWeight: 700,
      fontSize: '2.75rem',
      lineHeight: '100%',
      letterSpacing: '-0.02em',
      textTransform: 'uppercase',
    },
    readingDate: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.25rem',
      lineHeight: '24px',
      letterSpacing: '-0.02em',
    },
    loginTitle: {
      fontFamily: 'garage-gothic',
      fontWeight: 700,
      fontSize: '2.75rem',
      lineHeight: '100%',
      letterSpacing: '-0.02em',
      textTransform: 'uppercase',
    },
    readingPageReadingTimeSmall: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: '19px',
      letterSpacing: '-0.02em',
    },
    readingPageReadingTime: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '3.5rem',
      lineHeight: '100%',
      letterSpacing: '-0.02em',
    },
    readingPageSeatsAvailable: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: '24px',
      letterSpacing: '-0.02em',
    },
    readingPageQuote: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: '130%',
    },
    paperTitle: {
      fontFamily: 'garage-gothic',
      fontWeight: 700,
      fontSize: '2.75rem',
      lineHeight: '100%',
      letterSpacing: '-0.02em',
      textTransform: 'uppercase',
    },
    libraryCardTitle: {
      fontFamily: 'garage-gothic',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: '100%',
      letterSpacing: '-0.02em',
      textTransform: 'uppercase',
      color: COLORS.greyDark,
    },
    libraryCardTitlePostFix: {
      fontFamily: 'garage-gothic',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: '100%',
      letterSpacing: '-0.02em',
      textTransform: 'uppercase',
      color: COLORS.burnedOrange,
    },
    libraryCardName: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '2.625rem',
      lineHeight: '100%',
      color: COLORS.burnedOrange,
    },
    libraryCardEmail: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: '100%',
      color: COLORS.greyDark,
    },
    libraryCardReaderSince: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '100%',
      color: COLORS.grey,
    },
    reviewTitle: {
      fontFamily: 'garage-gothic',
      fontWeight: 700,
      fontSize: '60px',
      lineHeight: '90%',
      letterSpacing: '-0.02em',
      color: COLORS.greyDark,
    },
    reviewSubTitle: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: '130%',
      letterSpacing: '-0.02em',
      color: COLORS.grey,
    },
    reviewBody: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '130%',
      color: COLORS.grey,
    },
    quoteCardText: {
      // @TODO Insert CSS properties from QuoteCard.tsx
    },
    carouselTopicTitle: {
      fontFamily: 'garage-gothic',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '5.25rem',
      lineHeight: '100%',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '-0.02em',
    },
    chatHeaderTitleUser: {
      fontFamily: 'garage-gothic',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: '100%',
    },
  },
})

export default theme
