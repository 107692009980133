import { Box, Stack } from '@mui/material'
import Image from 'next/image'
import Link from 'next/link'

export type LogoProps = {
  variant?: 'light' | 'dark'
}

const Logo = ({ variant = 'light' }: LogoProps) => {
  const iconImgSrc =
    variant === 'dark' ? '/HL_logo_icon_white.svg' : '/HL_logo_icon.svg'
  const textImgSrc =
    variant === 'dark' ? '/HL_logo_text_white.svg' : '/HL_logo_text.svg'

  return (
    <Link href="/" passHref>
      <a>
        <Stack
          alignItems="center"
          direction="row"
          spacing="2.69px"
          height="32px"
        >
          <Box
            sx={{
              cursor: 'pointer',
              display: 'flex',
              maxWidth: 230,
              minWidth: 23.5,
              minHeight: 32,
            }}
          >
            <Image
              alt="The Human Library Logo Icon"
              height={32}
              width={23.5}
              objectFit="contain"
              src={iconImgSrc}
              unoptimized={process.env.NODE_ENV === 'development'}
            />
          </Box>
          <Box
            sx={{
              cursor: 'pointer',
              display: 'flex',
              maxWidth: 230,
              minWidth: 177.45,
              minHeight: 23.5,
            }}
          >
            <Image
              alt="The Human Library Logo Title Text"
              objectFit="contain"
              src={textImgSrc}
              height={23.5}
              width={177.45}
              unoptimized={process.env.NODE_ENV === 'development'}
            />
          </Box>
        </Stack>
      </a>
    </Link>
  )
}

export default Logo
