import { Breakpoint } from '@mui/system'

export const CONTAINER_MAX_WIDTH: Breakpoint = 'xl'

export const DEFAULT_LOCALE = 'en-US'

export const READINGS_FETCH_MORE_OFFSET = 32

export const BOOK_READINGS_FETCH_MORE_OFFSET = 7

export const ADMIN_FETCH_MORE_OFFSET = 7

export const READING_START_TIME_DELAY_MINUTES = 30

export const READING_START_TIME_INTERVAL_MINUTES = 15

export const CREATE_READING_UTC_START_HOUR = 11

export const CREATE_READING_UTC_END_HOUR = 1

export const READING_END_REMINDER_MINUTES = 5

export const READING_ABOUT_TO_START_MINUTES = 15

export const READING_DURATION = 30

export const MAX_READERS = 5

export const QUOTE_CARDS_EVERY_N_ROW = 2

export const MIN_VIDEO_WIDTH = 160

export const VIDEO_TILE_GAP = 16

export const VIDEO_TILE_RATIO = '16/9'
