import { ApolloClient, ApolloQueryResult } from '@apollo/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  GetOrganisationByIdQuery,
  GetOrganisationByIdQueryVariables,
} from '../../../generated/graphql'
import { RootState } from '../../../store'
import GetOrganisationById from '../graphql/queries/GetOrganisationById'

export const getOrganisationById = createAsyncThunk<
  ApolloQueryResult<GetOrganisationByIdQuery>,
  {
    client: ApolloClient<object>
    orgId: string
  },
  { state: RootState }
>('organisationList/getById', async ({ client, orgId }) => {
  return await client.query<
    GetOrganisationByIdQuery,
    GetOrganisationByIdQueryVariables
  >({
    fetchPolicy: 'no-cache',
    query: GetOrganisationById,
    variables: {
      orgId,
    },
  })
})
