import { createAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { fetchOnboardingProfile } from '../services/fetchOnboardingProfile'
import { insertOnboardingEulaAccept } from '../services/insertOnboardingEulaAccept'
import { updateOnboardingProfile } from '../services/updateOnboardingProfile'
import { updateOnboardingReadingPlanAccept } from '../services/updateOnboardingReadingPlanAccept'

export type OnboardingStep = 'eula' | 'profile' | 'readingplan' | 'introduction'

export type OnboardingState = {
  fetchCalled: boolean
  fetchError: boolean
  fetchLoading?: boolean
  eula: {
    hasAcceptedEula?: boolean
    latestEulaId?: string
  }
  person?: {
    preferredName?: string
    pronouns?: string
    personId: string
  }
  readingPlan: {
    constraints?: {
      id: string
      description: string
      title: string
    }[]
    durationName?: string
    id?: string
    initialReadings?: number
    readingPlanActivated?: boolean
    remainingReadings?: number
  }
  step?: OnboardingStep
  updateError: boolean
  updateLoading?: boolean
}

const initialState: OnboardingState = {
  eula: {},
  readingPlan: {},
  fetchCalled: false,
  fetchError: false,
  updateError: false,
}

export const setOnboardingFetchCalled = createAction<boolean>(
  `onboarding/setFetchCalled`
)

const setOnboardingStep = createAction<OnboardingStep>(`onboarding/setStep`)

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOnboardingProfile.pending, (state) => {
        state.fetchError = false
        state.fetchLoading = true
      })
      .addCase(fetchOnboardingProfile.fulfilled, (state, { payload }) => {
        state.fetchCalled = true
        state.fetchLoading = false

        const userPayload = payload.user
        const readingPlanPayload = payload.readingPlan

        if (userPayload.data.user_by_pk) {
          const person: OnboardingState['person'] = {
            personId: userPayload.data.user_by_pk.person.id,
            preferredName: userPayload.data.user_by_pk.person.name || undefined,
            pronouns: userPayload.data.user_by_pk.person.pronouns || undefined,
          }

          state.person = person

          if (readingPlanPayload && readingPlanPayload.data) {
            const readingPlan: OnboardingState['readingPlan'] = {
              constraints:
                readingPlanPayload.data.user_by_pk?.readingplan?.pillarconstraints.map(
                  ({ pillar }) => {
                    return {
                      id: pillar.id,
                      description: pillar.description || '',
                      title: pillar.title || '',
                    }
                  }
                ) || [],
              durationName:
                readingPlanPayload.data.user_by_pk?.readingplan
                  ?.readingPlanDuration.translation[0].name,
              id: readingPlanPayload.data.user_by_pk?.readingplan?.id,
              initialReadings:
                readingPlanPayload.data.user_by_pk?.readingplan
                  ?.initialreadings || 0,
              remainingReadings:
                readingPlanPayload.data.user_by_pk?.readingplan
                  ?.remainingreadings || undefined,
              readingPlanActivated:
                !!readingPlanPayload.data.user_by_pk?.readingplan?.activated,
            }

            state.readingPlan = readingPlan
          }
        }

        if (
          Array.isArray(userPayload.data.eula) &&
          userPayload.data.eula.length > 0
        ) {
          const latestEulaId = userPayload.data.eula[0].id || undefined

          const eula: OnboardingState['eula'] = {
            hasAcceptedEula:
              userPayload.data.eula[0].id &&
              userPayload.data.user_by_pk?.person.eula_acceptances.find(
                ({ eula_id }) => {
                  return eula_id === latestEulaId
                }
              ),
            latestEulaId: latestEulaId,
          }

          state.eula = eula
        }

        if (!state.step && state.person) {
          if (!state.eula.hasAcceptedEula) {
            state.step = 'eula'
          } else if (!state.person.preferredName || !state.person.pronouns) {
            state.step = 'profile'
          } else if (!state.readingPlan?.readingPlanActivated) {
            state.step = 'readingplan'
          } else {
            state.step = 'introduction'
          }
        }
      })
      .addCase(insertOnboardingEulaAccept.pending, (state, { payload }) => {
        state.updateError = false
        state.updateLoading = true
      })
      .addCase(insertOnboardingEulaAccept.fulfilled, (state, { payload }) => {
        state.updateError = false
        state.updateLoading = false

        state.eula = {
          ...state.eula,
          hasAcceptedEula: true,
        }

        state.step = 'profile'
      })
      .addCase(insertOnboardingEulaAccept.rejected, (state, { payload }) => {
        state.updateError = true
        state.updateLoading = false
      })
      .addCase(setOnboardingFetchCalled, (state, { payload }) => {
        state.fetchCalled = payload
      })
      .addCase(updateOnboardingProfile.pending, (state) => {
        state.updateError = false
        state.updateLoading = true
      })
      .addCase(updateOnboardingProfile.fulfilled, (state, { payload }) => {
        state.updateError = false
        state.updateLoading = false

        const persons = payload.data?.update_person?.returning
        if (
          Array.isArray(persons) &&
          persons.length > 0 &&
          persons[0].users.length > 0
        ) {
          if (
            ['reader', 'superreader'].includes(persons[0].users[0].role.name) &&
            !state.readingPlan.readingPlanActivated
          ) {
            state.step = 'readingplan'
          } else {
            state.step = 'introduction'
          }
        }
      })
      .addCase(updateOnboardingProfile.rejected, (state, { error }) => {
        state.updateError = true
        state.updateLoading = false
      })

      .addCase(updateOnboardingReadingPlanAccept.pending, (state) => {
        state.updateError = false
        state.updateLoading = true
      })
      .addCase(
        updateOnboardingReadingPlanAccept.fulfilled,
        (state, { payload }) => {
          state.updateLoading = false

          if (payload.data?.update_readingplan_by_pk?.activated) {
            state.step = 'introduction'
          } else {
            state.updateError = true
          }
        }
      )
      .addCase(
        updateOnboardingReadingPlanAccept.rejected,
        (state, { error }) => {
          state.updateError = true
          state.updateLoading = false
        }
      )

      .addCase(setOnboardingStep, (state, { payload }) => {
        state.step = payload
      })
  },
})

export const selectOnboardingLatestEulaId = ({ onboarding }: RootState) => {
  return onboarding.eula?.latestEulaId
}

export const selectOnboardingEulaAccepted = ({ onboarding }: RootState) => {
  return onboarding.eula?.hasAcceptedEula
}

export const selectOnboardingFetchCalled = ({ onboarding }: RootState) => {
  return !!onboarding.fetchCalled
}

export const selectOnboardingStep = ({ onboarding }: RootState) => {
  return onboarding.step
}

export const selectOnboardingPersonId = ({ onboarding }: RootState) => {
  return onboarding.person?.personId
}

export const selectOnboardingPreferredName = ({ onboarding }: RootState) => {
  return onboarding.person?.preferredName
}

export const selectOnboardingPronouns = ({ onboarding }: RootState) => {
  return onboarding.person?.pronouns
}

export const selectOnboardingConstraints = ({ onboarding }: RootState) => {
  return onboarding.readingPlan.constraints
}

export const selectOnboardingDurationName = ({ onboarding }: RootState) => {
  return onboarding.readingPlan.durationName
}

export const selectOnboardingInitialReadings = ({ onboarding }: RootState) => {
  return onboarding.readingPlan.initialReadings
}

export const selectOnboardingReadingPlanActivated = ({
  onboarding,
}: RootState) => {
  return onboarding.readingPlan.readingPlanActivated
}

export const selectOnboardingRemainingReadings = ({
  onboarding,
}: RootState) => {
  return onboarding.readingPlan.remainingReadings
}

export const selectOnboardingReadingPlanId = ({ onboarding }: RootState) => {
  return onboarding.readingPlan.id
}

export const selectOnboardingFetchLoading = ({ onboarding }: RootState) => {
  return !!onboarding.fetchLoading
}

export const selectOnboardingUpdateLoading = ({ onboarding }: RootState) => {
  return !!onboarding.updateLoading
}
