import { HLO } from '../../../types/global'

export type ChatVariant = 'admin' | 'user' | 'room-list'

export default function getChatVariant(
  userRole: HLO.Role,
  toUserId: string | undefined
) {
  let variant: ChatVariant

  if (['hlo-admin', 'librarian'].includes(userRole)) {
    if (toUserId) {
      variant = 'admin'
    } else {
      variant = 'room-list'
    }
  } else {
    variant = 'user'
  }

  return variant
}
