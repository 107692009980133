import { CacheProvider, EmotionCache } from '@emotion/react'
import { Box, ScopedCssBaseline, Stack } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { NextPage } from 'next'
import { SessionProvider } from 'next-auth/react'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { SnackbarProvider } from 'notistack'
import { Provider } from 'react-redux'
import BasicHeader from '../components/BasicHeader'
import Feedback from '../components/Feedback'
import Footer from '../components/Footer'
import Header from '../components/Header'
import ChatDrawerContainer from '../features/Chat/containers/ChatDrawerContainer'
import ChatRoomContainer from '../features/Chat/containers/ChatRoomContainer'
import ChatRoomListContainer from '../features/Chat/containers/ChatRoomListContainer'
import LocalizationContainer from '../features/localization/container/LocalizationContainer'
import ReadingPlanDataContainer from '../features/readingPlan/containers/ReadingPlanDataContainer'
import useAuthClaims from '../hooks/useAuthClaims'
import { MainLayout } from '../layouts/MainLayout'
import AuthorizedApolloProvider from '../providers/AuthorizedApolloProvider'
import { store } from '../store'
import '../styles/SnackBarContainer.css'
import COLORS from '../styles/colors'
import '../styles/globals.css'
import theme from '../theme'
import { NextPageWithLayout } from '../types/global'
import createEmotionCache from '../util/createEmotionCache'

export type ComponentWrapperProps = {
  children: any
}

const ComponentWrapper = ({ children }: ComponentWrapperProps) => {
  const { loading } = useAuthClaims()

  if (loading) {
    return <Feedback type="loading" />
  }

  return children
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
  emotionCache?: EmotionCache
}

const clientSideEmotionCache = createEmotionCache()

function App({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: AppPropsWithLayout) {
  const router = useRouter()
  const getLayout =
    Component.getLayout ?? ((page: NextPage) => <MainLayout>{page}</MainLayout>)

  let header
  if (router.asPath.startsWith('/video-call/test')) {
    header = <BasicHeader />
  } else if (!router.asPath.startsWith('/reading-room')) {
    header = <Header />
  }

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <Head>
          <link href="/manifest.json" rel="manifest" />
          <meta content="initial-scale=1, width=device-width" name="viewport" />
          {process.env.NODE_ENV !== 'production' && (
            <meta name="robots" content="no-index" key="robots" />
          )}
        </Head>
        <SessionProvider
          session={pageProps.session}
          refetchInterval={0}
          refetchOnWindowFocus={false}
        >
          <Provider store={store}>
            <AuthorizedApolloProvider>
              <LocalizationContainer />
              <ScopedCssBaseline style={{ height: '100%' }}>
                <Stack height="100%">
                  {header}
                  <Box flexGrow={1}>
                    <SnackbarProvider
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      maxSnack={3}
                      style={{
                        background: COLORS.burnedOrange,
                        color: '#fff',
                      }}
                    >
                      <ComponentWrapper>
                        {getLayout(<Component {...pageProps} />)}
                      </ComponentWrapper>
                    </SnackbarProvider>
                  </Box>
                  <Footer />
                </Stack>
                <ReadingPlanDataContainer />
                <ChatDrawerContainer>
                  <ChatRoomContainer />
                  <ChatRoomListContainer />
                </ChatDrawerContainer>
              </ScopedCssBaseline>
            </AuthorizedApolloProvider>
          </Provider>
        </SessionProvider>
      </ThemeProvider>
    </CacheProvider>
  )
}

export default appWithTranslation(App)
