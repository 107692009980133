import { List, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useRef } from 'react'
import Feedback from '../../../components/Feedback'
import { GetChatMessagesSubscription } from '../../../generated/graphql'
import { HLO } from '../../../types/global'
import ChatMessage from '../components/ChatMessage'
import ChatMessageInputField from '../components/ChatMessage/ChatMessageInputField'
import JoinChatButton from '../components/JoinChatButton'
import { ChatVariant } from '../util/getChatVariant'

export type ChatRoomProps = {
  error: boolean
  language?: HLO.Language
  loading: boolean
  messages: GetChatMessagesSubscription['chatMessages']
  onJoinClick: () => void
  showJoinChatButton: boolean
  userId: string
  variant: ChatVariant
}

const ChatRoom = ({
  error,
  language,
  loading,
  messages,
  onJoinClick,
  showJoinChatButton,
  userId,
  variant,
}: ChatRoomProps) => {
  const messagesEndRef = useRef<null | HTMLDivElement>(null)

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  return (
    <>
      <Stack height="100%" sx={{ overflowY: 'scroll' }}>
        {error && (
          <>
            <Feedback type="error" text="Could not subscribe to messages" />
          </>
        )}
        <Box flexGrow={1}>
          {loading ? (
            <Feedback type="loading" />
          ) : (
            <List
              sx={{
                height: '100%',
                overflowY: 'auto',
                p: '10px',
              }}
            >
              {messages.map((message) => {
                return (
                  <ChatMessage
                    language={language}
                    key={message.id}
                    message={message}
                    variant={variant}
                  />
                )
              })}
            </List>
          )}
          <div ref={messagesEndRef} />
        </Box>

        {showJoinChatButton ? (
          <Stack alignItems="center" mb="32px">
            <JoinChatButton onClick={onJoinClick} />
          </Stack>
        ) : (
          <ChatMessageInputField disabled={loading} userId={userId} />
        )}
      </Stack>
    </>
  )
}

export default ChatRoom
