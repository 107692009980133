import { ApolloClient, ApolloQueryResult } from '@apollo/client'
import {
  createAction,
  createAsyncThunk,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit'
import {
  GetLocalizationQuery,
  GetLocalizationQueryVariables,
} from '../../../generated/graphql'
import { HLO } from '../../../types/global'
import getLocalization from '../services/getLocalization'
import { RootState } from './../../../store'

export type ChatDrawerState = {
  called?: boolean
  defaultLanguage?: HLO.Language
  error?: SerializedError
  loading?: boolean
  language?: HLO.Language
}

const initialState: ChatDrawerState = {
  language: {
    id: '65470729-ac24-459a-bd7a-f57cdb9206e4',
    isocode: 'en-US',
  },
}

export const setLanguage = createAction<HLO.Language>(
  'localization/setLanguage'
)

export const fetchLocalization = createAsyncThunk<
  ApolloQueryResult<GetLocalizationQuery>,
  { client: ApolloClient<object>; variables: GetLocalizationQueryVariables },
  { state: RootState }
>('localization/fetch', async ({ client, variables }, thunkAPI) => {
  return getLocalization(client, variables)
})

export const localizationSlice = createSlice({
  name: 'localization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setLanguage, (state, { payload }) => {
      state.language = payload
    })
    builder
      .addCase(fetchLocalization.pending, (state) => {
        state.error = undefined
        state.loading = true
      })
      .addCase(fetchLocalization.fulfilled, (state, { payload }) => {
        state.called = true
        state.defaultLanguage =
          Array.isArray(payload.data.language) &&
          payload.data.language.length > 0
            ? payload.data.language[0]
            : undefined

        state.language = payload.data.user_by_pk?.person.language || undefined
        state.loading = false
      })
      .addCase(fetchLocalization.rejected, (state, { error }) => {
        state.called = true
        state.loading = false
        state.error = error
      })
  },
})

export const selectDefaultLanguage = ({ localization }: RootState) => {
  return localization.defaultLanguage
}

export const selectLanguage = ({ localization }: RootState) => {
  return localization.language
}

export const selectLocalizationCalled = ({ localization }: RootState) => {
  return localization.called
}

export const selectLocalizationError = ({ localization }: RootState) => {
  return localization.error
}

export const selectLocalizationLoading = ({ localization }: RootState) => {
  return localization.loading
}
