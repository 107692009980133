import {
  faBook,
  faUser,
  faUserGraduate,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons'
import { HLO } from '../../../types/global'

export const getIconByRole = (role: HLO.Role) => {
  switch (role) {
    case 'hlo-admin':
      return faUserShield
    case 'librarian':
      return faUserGraduate
    case 'book':
      return faBook
    default:
      return faUser
  }
}
