export declare type NextJsI18NPageProps = {
  defaultLocale: string
  locales: string[]
  locale: string
}

export declare type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

export enum UserMenuItemType {
  BUTTON,
  LINK,
  SEPERATOR,
}

export namespace HLO {
  export declare type Role =
    | 'guest'
    | 'reader'
    | 'book'
    | 'librarian'
    | 'hlo-admin'
    | 'superreader'

  export declare type ParticipationState =
    | 'invite'
    | 'decline'
    | 'signup'
    | 'join'
    | 'leave'
    | 'cancel'

  export declare type ReadingState =
    | 'Scheduled'
    | 'Starting'
    | 'In Progress'
    | 'Ending'
    | 'Completed'
    | 'Canceled'
    | 'Incomplete'

  export declare type Language = { id: string; isocode: string }
  export declare type Timezone = string
}
