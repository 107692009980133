import { faComment } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@mui/material'
import { useTranslation } from 'next-i18next'

export type JoinChatButtonProps = {
  onClick: () => void
}

const JoinChatButton = ({ onClick }: JoinChatButtonProps) => {
  const { t } = useTranslation('Chat')
  return (
    <Button
      color="secondary"
      onClick={onClick}
      sx={{ borderRadius: '8px', width: '140px' }}
      startIcon={<FontAwesomeIcon icon={faComment} />}
      variant="contained"
    >
      {t('joinChatButton.label')}
    </Button>
  )
}

export default JoinChatButton
