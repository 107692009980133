const COLORS = {
  white: '#fff',
  greyDark: '#34352B',
  grey: '#71726E',
  red: '#F65757',
  burnedOrange: '#D14006',
  borderGrey: '#DADADA',
  orange: '#DA3D1B',
  orangeBackground: '#FAC8BD',
  beige: '#FAF7F4',
  backgroundGreyLight: '#F6F6F7',
  darkerOrange: '#BF3618',
  filterButtonIcon: '#16161DCC',
  black: '#16161D',
  darkModeBackground: '#17181A',
}

export default COLORS
