export const getInitials = (name?: string) => {
  if (!name || name === 'N/A') {
    return 'N/A'
  }

  let result = ''
  let nameSplit = name.split(' ')

  if (nameSplit?.length > 1) {
    const firstName = nameSplit[0]
    const lastName = nameSplit[nameSplit.length - 1]

    if (firstName.length > 0) {
      result = firstName[0]
    }

    if (lastName.length > 0) {
      result += lastName[0]
    }
  } else {
    result = name[0]
  }

  return result?.toUpperCase() || 'N/A'
}
