import { Typography } from '@mui/material'

const SkipToMain = () => {
  return (
    <a
      href="#main-content"
      style={{
        position: 'absolute',
        top: '-9999px',
        left: '-9999px',
        overflow: 'hidden',
      }}
    >
      <Typography>Skip to main</Typography>
    </a>
  )
}

export default SkipToMain
