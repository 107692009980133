import { Stack, Typography } from '@mui/material'
import COLORS from '../../../styles/colors'
import { HLO } from '../../../types/global'
import formatReadingDate from '../../../util/formatReadingDate'

export type ReadingPlanStatusProps = {
  expireDate: Date | undefined
  hasNoReadingPlan: boolean
  isExpired: boolean
  language: HLO.Language
  remainingReadings: number
}

const ReadingPlanStatus = ({
  expireDate,
  hasNoReadingPlan,
  isExpired,
  language,
  remainingReadings,
}: ReadingPlanStatusProps) => {
  const topFontStyle = {
    color: COLORS.greyDark,
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '100%',
    letterSpacing: '-0.02em',
  }

  const bottomFontStyle = {
    color: COLORS.grey,
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '100%',
    textAlign: 'right',
    letterSpacing: '-0.02em',
  }

  if (hasNoReadingPlan === true) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        border={`1px solid ${COLORS.burnedOrange}`}
        height="40px"
        textTransform="uppercase"
        width="232px"
      >
        <Typography color={COLORS.burnedOrange} variant="inter_h4">
          No Reading Plan
        </Typography>
      </Stack>
    )
  }

  if (isExpired === true) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        border={`1px solid ${COLORS.burnedOrange}`}
        height="40px"
        textTransform="uppercase"
        width="280px"
      >
        <Typography color={COLORS.burnedOrange} variant="inter_h4">
          Reading Plan expired
        </Typography>
      </Stack>
    )
  }

  return (
    <Stack direction="row" spacing="24px">
      {remainingReadings !== undefined && (
        <Stack
          alignItems="flex-start"
          direction="column"
          spacing="4px"
          tabIndex={0}
          aria-label={`${remainingReadings} READINGS REMAINING`}
        >
          <Typography sx={topFontStyle} component="span" aria-disabled="true">
            {remainingReadings} READINGS
          </Typography>
          <Typography
            sx={bottomFontStyle}
            component="span"
            aria-disabled="true"
          >
            REMAINING
          </Typography>
        </Stack>
      )}
      {expireDate && (
        <Stack
          alignItems="flex-start"
          direction="column"
          spacing="4px"
          tabIndex={0}
          aria-label={`PLAN EXPIRES: ${formatReadingDate(
            language.isocode,
            expireDate,
            true
          )}`}
        >
          <Typography sx={topFontStyle}>
            {formatReadingDate(language.isocode, expireDate, true)}
          </Typography>
          <Typography sx={bottomFontStyle}>PLAN EXPIRES</Typography>
        </Stack>
      )}
    </Stack>
  )
}

export default ReadingPlanStatus
