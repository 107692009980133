import { ListItemText, Typography } from '@mui/material'
import COLORS from '../../../../styles/colors'
import ChatMessageListItem from './ChatMessageListItem'

export type ChatMessageEventProps = {
  icon?: JSX.Element
  value: string | undefined
}

const ChatMessageEvent = ({ icon, value }: ChatMessageEventProps) => {
  return (
    <ChatMessageListItem>
      <ListItemText sx={{ textAlign: 'center' }}>
        {icon}

        <Typography color={COLORS.greyDark} variant="inter_h8">
          {value}
        </Typography>
      </ListItemText>
    </ChatMessageListItem>
  )
}

export default ChatMessageEvent
