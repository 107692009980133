import { useAppSelector } from '../../../store'
import useLocalization from '../../localization/hooks/useLocalization'
import {
  selectHasNoReadingPlan,
  selectReadingPlanExpireDate,
  selectReadingPlanIsExpired,
  selectReadingPlanRemainingReadings,
} from '../slices/readingPlanSlice'
import ReadingPlanStatus from '../views/ReadingPlanStatus'

const ReadingPlanStatusContainer = () => {
  const expireDate = useAppSelector(selectReadingPlanExpireDate)
  const hasNoReadingPlan = useAppSelector(selectHasNoReadingPlan)
  const isExpired = useAppSelector(selectReadingPlanIsExpired)
  const remainingReadings = useAppSelector(selectReadingPlanRemainingReadings)
  const { language } = useLocalization()

  if (language && hasNoReadingPlan !== undefined) {
    return (
      <ReadingPlanStatus
        expireDate={expireDate}
        hasNoReadingPlan={hasNoReadingPlan}
        isExpired={!!isExpired}
        language={language}
        remainingReadings={remainingReadings || 0}
      />
    )
  }

  return <></>
}

export default ReadingPlanStatusContainer
