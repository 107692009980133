import { ApolloClient, ApolloQueryResult } from '@apollo/client'
import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit'
import { ChatRoomGetMessageTypesQuery } from '../../../generated/graphql'
import { RootState } from '../../../store'
import { getMessageTypes } from '../services/getMessageTypes'

export type ChatState = {
  called?: boolean
  data?: ChatRoomGetMessageTypesQuery['chat_message_type']
  error?: SerializedError
  loading?: boolean
}

const initialState: ChatState = {}

export const fetchChatMessageTypes = createAsyncThunk<
  ApolloQueryResult<ChatRoomGetMessageTypesQuery>,
  { client: ApolloClient<object> },
  { state: RootState }
>('chatMessageTypes/fetch', async ({ client }, thunkAPI) => {
  return getMessageTypes(client)
})

export const chatMessageTypesSlice = createSlice({
  name: 'chatMessageTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatMessageTypes.pending, (state) => {
        state.error = undefined
        state.loading = true
      })
      .addCase(fetchChatMessageTypes.fulfilled, (state, { payload }) => {
        state.called = true
        state.data = payload.data.chat_message_type
        state.loading = false
      })
      .addCase(fetchChatMessageTypes.rejected, (state, { error }) => {
        state.called = true
        state.loading = false
        state.error = error
      })
  },
})

export const selectChatMessageTypeLoading = ({
  chatMessageTypes,
}: RootState) => {
  return !!chatMessageTypes.loading
}

export const selectChatMessageTypeCalled = ({
  chatMessageTypes,
}: RootState) => {
  return chatMessageTypes.called
}

export const selectChatMessageTypeData = ({ chatMessageTypes }: RootState) => {
  return chatMessageTypes.data
}

export const selectChatMessageTypeError = ({ chatMessageTypes }: RootState) => {
  return chatMessageTypes.error
}
