import { ApolloClient, gql } from '@apollo/client'
import {
  GetLocalizationQuery,
  GetLocalizationQueryVariables,
} from '../../../generated/graphql'

const GET_DATA = gql`
  query GetLocalization($userId: uuid!) {
    language(limit: 1, where: { isdefault: { _eq: true } }) {
      id
      isocode
    }

    user_by_pk(id: $userId) {
      person {
        language {
          id
          isocode
        }
      }
    }
  }
`

export default async function getLocalization(
  client: ApolloClient<object>,
  variables: GetLocalizationQueryVariables
) {
  return client.query<GetLocalizationQuery, GetLocalizationQueryVariables>({
    fetchPolicy: 'no-cache',
    query: GET_DATA,
    variables,
  })
}
