import { gql } from '@apollo/client'

export const CORE_READING_FIELDS = gql`
  fragment CoreReadingFields on reading {
    id
    readingstart
    language {
      id
      name
    }
    maxreaders
    minreaders
    participationEventName
    readingEventStatusName
    duration_id
    duration {
      minutes
    }
    topicName
    topic {
      id
      name
      slug
      description
      short_description
      quote
      book_topics {
        topic_id
        book_id
        topicname
      }
      pillar_topics {
        isprimary
        pillar {
          id
          title
          description
        }
      }
    }
    book {
      id
      name
      created
      topicName
      pronouns
      user_id
      book_topics {
        topic_id
        book_id
        topicname
        topic {
          id
          name
        }
      }
    }
    seats_available
  }
`
