import { ApolloClient, gql } from '@apollo/client'
import {
  NotificationsInsertChatRoomMutation,
  NotificationsInsertChatRoomMutationVariables,
} from '../../../generated/graphql'

const INSERT_CHAT_ROOM = gql`
  mutation NotificationsInsertChatRoom(
    $active: Boolean!
    $ownerUserId: uuid!
    $userId: uuid!
  ) {
    insert_chat_room_one(
      object: { active: $active, owner_user_id: $ownerUserId, user_id: $userId }
      on_conflict: {
        constraint: chat_room_pkey
        update_columns: [active, owner_user_id]
      }
    ) {
      user_id
    }
  }
`

export async function insertChatRoom(
  client: ApolloClient<object>,
  variables: NotificationsInsertChatRoomMutationVariables
) {
  return client.mutate<
    NotificationsInsertChatRoomMutation,
    NotificationsInsertChatRoomMutationVariables
  >({
    fetchPolicy: 'no-cache',
    mutation: INSERT_CHAT_ROOM,
    variables,
  })
}
