import { ApolloClient, FetchResult } from '@apollo/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  InsertOnboardingEulaAcceptMutation,
  InsertOnboardingEulaAcceptMutationVariables,
} from '../../../generated/graphql'
import { RootState } from '../../../store'
import InsertOnboardingEulaAccept from '../graphql/mutations/InsertOnboardingEulaAccept'

export const insertOnboardingEulaAccept = createAsyncThunk<
  FetchResult<InsertOnboardingEulaAcceptMutation>,
  {
    client: ApolloClient<object>
    variables: InsertOnboardingEulaAcceptMutationVariables
  },
  { state: RootState }
>('onboardingEulaAccept/insert', async ({ client, variables }, thunkAPI) => {
  return client.mutate<
    InsertOnboardingEulaAcceptMutation,
    InsertOnboardingEulaAcceptMutationVariables
  >({
    fetchPolicy: 'no-cache',
    mutation: InsertOnboardingEulaAccept,
    variables,
  })
})
