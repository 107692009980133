import { gql } from '@apollo/client'

const GetBookAggregate = gql`
  query GetBookAggregate($where: book_bool_exp) {
    book_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export default GetBookAggregate
