import { ApolloClient } from '@apollo/client'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  GetReadingPlanByUserIdQuery,
  GetReadingPlanByUserIdQueryVariables,
} from '../../../generated/graphql'
import getNumberOfBooksInReadingPlan from '../services/getNumberOfBooksInReadingPlan'
import getReadingPlanByUserId from '../services/getReadingPlanByUserId'
import { RootState } from './../../../store'

export type ReadingPlanState = {
  createdAt?: string
  createdBy?: string
  id?: string
  error?: boolean
  initialReadings?: number
  isExpired?: boolean
  hasNoReadingPlan?: boolean
  loading?: boolean
  numberOfBooksInReadingPlan?: number
  pillarConstraints?: { id: string; title: string }[]
  remainingReadings?: number
  updatedAt?: string
  updatedBy?: string
  validTo?: string
}

const initialState: ReadingPlanState = {}

export const fetchNumberOfBooksInReadingPlan = createAsyncThunk<
  number | undefined,
  {
    client: ApolloClient<object>
    pillarConstraints: ReadingPlanState['pillarConstraints']
  },
  { state: RootState }
>(
  'readingPlan/fetchNumberOfBooksInReadingPlan',
  async ({ client, pillarConstraints }, _) => {
    return getNumberOfBooksInReadingPlan(client, pillarConstraints)
  }
)

export const fetchReadingPlanByUserId = createAsyncThunk<
  GetReadingPlanByUserIdQuery['readingplan']['0'] | undefined,
  {
    client: ApolloClient<object>
    variables: GetReadingPlanByUserIdQueryVariables
  },
  { state: RootState }
>('readingPlan/fetchByUserId', async ({ client, variables }, _) => {
  return getReadingPlanByUserId(client, variables)
})

export const readingPlanSlice = createSlice({
  name: 'readingPlan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReadingPlanByUserId.pending, (state) => {
        state.error = undefined
        state.loading = true
      })
      .addCase(fetchReadingPlanByUserId.fulfilled, (state, { payload }) => {
        state.error = false
        state.loading = false

        state.hasNoReadingPlan = payload === undefined

        state.id = payload?.id
        state.createdAt = payload?.created
        state.createdBy = payload?.creator?.personName || undefined
        state.initialReadings = payload?.initialreadings
        state.remainingReadings = payload?.remainingreadings
        state.pillarConstraints =
          (payload &&
            payload.pillarconstraints.map(({ pillar }) => {
              return { id: pillar.id, title: pillar.title }
            })) ||
          []
        state.updatedAt = payload?.updated
        state.updatedBy = payload?.updatedBy?.personName || undefined
        state.validTo = payload?.valid_to

        const readingPlanValidTo =
          payload && payload.valid_to ? new Date(payload.valid_to) : undefined

        if (readingPlanValidTo) {
          const now = new Date()
          now.setHours(readingPlanValidTo.getHours())
          now.setMinutes(readingPlanValidTo.getMinutes())
          now.setSeconds(readingPlanValidTo.getSeconds())

          state.isExpired = readingPlanValidTo.getTime() < now.getTime()
        } else {
          state.isExpired = undefined
        }
      })
      .addCase(fetchReadingPlanByUserId.rejected, (state, { error }) => {
        state.loading = false
        state.error = true
      })
      .addCase(fetchNumberOfBooksInReadingPlan.pending, (state) => {
        state.numberOfBooksInReadingPlan = undefined
      })
      .addCase(
        fetchNumberOfBooksInReadingPlan.fulfilled,
        (state, { payload }) => {
          state.numberOfBooksInReadingPlan = payload
        }
      )
      .addCase(fetchNumberOfBooksInReadingPlan.rejected, (state) => {
        state.numberOfBooksInReadingPlan = undefined
      })
  },
})

export const selectNumberOfBooksInReadingPlan = ({
  readingPlan,
}: RootState) => {
  return readingPlan.numberOfBooksInReadingPlan
}

export const selectReadingPlanCreatedAt = ({ readingPlan }: RootState) => {
  if (readingPlan.createdAt) {
    return new Date(readingPlan.createdAt)
  }

  return undefined
}

export const selectReadingPlanCreatedBy = ({ readingPlan }: RootState) => {
  return readingPlan.createdBy
}

export const selectReadingPlanExpireDate = ({ readingPlan }: RootState) => {
  if (readingPlan.validTo) {
    return new Date(readingPlan.validTo)
  }

  return undefined
}

export const selectHasNoReadingPlan = ({ readingPlan }: RootState) => {
  return readingPlan.hasNoReadingPlan
}

export const selectReadingPlanLoading = ({ readingPlan }: RootState) => {
  return readingPlan.loading
}

export const selectReadingPlanInitialReadings = ({
  readingPlan,
}: RootState) => {
  return readingPlan.initialReadings
}

export const selectReadingPlanIsExpired = ({ readingPlan }: RootState) => {
  return readingPlan.isExpired
}

export const selectReadingPlanHasRemainingReadings = ({
  readingPlan,
}: RootState) => {
  return (readingPlan.remainingReadings || 0) > 0
}

export const selectReadingPlanRemainingReadings = ({
  readingPlan,
}: RootState) => {
  return readingPlan.remainingReadings
}

export const selectReadingPlanUpdatedAt = ({ readingPlan }: RootState) => {
  if (readingPlan.updatedAt) {
    return new Date(readingPlan.updatedAt)
  }

  return undefined
}

export const selectReadingPlanUpdatedBy = ({ readingPlan }: RootState) => {
  return readingPlan.updatedBy
}

export const selectPillarConstraints = ({ readingPlan }: RootState) => {
  return readingPlan.pillarConstraints
}
