import { DefaultSession } from 'next-auth'
import { useSession } from 'next-auth/react'
import { UserClaims } from '../lib/userClaims'

export type useAuthClaimsResults = {
  isAuthenticated: boolean
  loading: boolean
  claims: UserClaims | undefined
  user?: DefaultSession['user']
  users: UserClaims[]
  userId: string | undefined
  hasuraAccessToken: string | undefined
}

export default function useAuthClaims(): useAuthClaimsResults {
  const session = useSession()

  const claims = session.data?.claims
  const isAuthenticated = session.status === 'authenticated'
  const loading = session.status === 'loading'
  const user = session.data?.user
  const users = session.data?.users || []
  const userId = claims?.id
  const hasuraAccessToken = session.data?.hasuraAccessToken

  return {
    claims,
    isAuthenticated,
    hasuraAccessToken,
    loading,
    user,
    userId,
    users,
  }
}
