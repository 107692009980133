import { Logout as LogoutIcon } from '@mui/icons-material'
import { AppBar, Stack, Tooltip, useMediaQuery } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { Box, useTheme } from '@mui/system'
import { signIn } from 'next-auth/react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import useLocalization from '../features/localization/hooks/useLocalization'
import useAuthClaims from '../hooks/useAuthClaims'
import { handleSignOut } from '../lib/auth0'
import { HLO, UserMenuItemType } from '../types/global.d'
import Logo from './Logo'
import PageContainer from './PageContainer'
import UserBadge from './UserBadge'
import UserMenu, { UserMenuItem } from './UserMenu'

const BasicHeader = () => {
  const { claims, loading } = useAuthClaims()
  const role = claims?.role || ''
  const router = useRouter()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const handleMenuItemClick = ({ onClick, type, value }: UserMenuItem) => {
    if (type === UserMenuItemType.LINK && typeof value === 'string') {
      router.push(value)
    }

    if (type === UserMenuItemType.BUTTON && typeof onClick === 'function') {
      onClick()
    }
  }

  const getUserMenuItems = (userRole: HLO.Role) => {
    const userMenuItems: UserMenuItem[] = []

    userMenuItems.push({
      icon: <LogoutIcon />,
      label: 'Log out',
      type: UserMenuItemType.BUTTON,
      onClick: () => handleSignOut(),
    })

    return userMenuItems
  }

  const actions = [
    claims ? (
      <Stack
        alignItems="center"
        direction="row"
        key="HeaderAction_Logout"
        spacing="24px"
      >
        <Stack alignItems="center" direction="row" spacing="12px">
          <Stack alignItems="center" direction="row" spacing="0.5rem">
            <UserMenu
              items={loading ? [] : getUserMenuItems(claims?.role)}
              onMenuItemClick={handleMenuItemClick}
            >
              <UserBadge name={claims.name || ''} />
            </UserMenu>
          </Stack>
        </Stack>
      </Stack>
    ) : (
      <Tooltip title="Log in">
        <IconButton onClick={() => signIn('auth0')}>
          <UserBadge />
        </IconButton>
      </Tooltip>
    ),
  ]

  return (
    <AppBar
      role="header"
      position="relative"
      aria-label={`Group containing a logo, links to the following pages: The Bookshelf, Pillars of Prejudice, Frequently Asked Questions and About us. Also has information about remaining readings and when the Reading Plan expires. Last are two buttons: A chat and a user menu button`}
      component="div"
    >
      <PageContainer disableGutters>
        {isSmall ? (
          <Stack direction="column" alignItems="center" padding="24px">
            <Stack alignItems="center" direction="row" spacing={8}>
              <Logo />
              <Box sx={{}}>{actions}</Box>
            </Stack>
          </Stack>
        ) : (
          <Stack
            color=""
            direction="row"
            alignItems="center"
            height="88px"
            padding="24px"
          >
            <Logo />
            {['hlo-admin'].includes(role) && (
              <Box
                sx={{
                  ml: '12px',
                  display: 'flex',
                  maxWidth: 52.7,
                  minWidth: 52.7,
                  minHeight: 23.5,
                }}
              >
                <Image
                  alt="The Human Library Logo"
                  height={23.5}
                  width={52.7}
                  objectFit="contain"
                  src="/Admin.svg"
                  unoptimized={process.env.NODE_ENV === 'development'}
                />
              </Box>
            )}

            <Box
              sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
              }}
            >
              {!loading && actions}
            </Box>
          </Stack>
        )}
      </PageContainer>
    </AppBar>
  )
}

export default BasicHeader
