// Manual re-creation of Apollo's useSubscription hook
// Why? useSubscription breaks with an "Observable canceled prematurely" error upon...
//  1. fast-refresh
//  2. quick mounting/unmounting

import {
  DocumentNode,
  OperationVariables,
  TypedDocumentNode,
  useApolloClient,
} from '@apollo/client'
import { useEffect, useState } from 'react'

export type UseCustomSubscriptionConfig<
  Subscription,
  SubscriptionVariables extends OperationVariables = any
> = {
  query: DocumentNode | TypedDocumentNode<Subscription, SubscriptionVariables>
  variables?: SubscriptionVariables
  skip?: boolean
}
export default function useCustomSubscription<
  Subscription,
  SubscriptionVariables extends OperationVariables = any
>({
  query,
  variables,
  skip,
}: UseCustomSubscriptionConfig<Subscription, SubscriptionVariables>) {
  const client = useApolloClient()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [data, setData] = useState<Subscription | undefined | null>()

  useEffect(() => {
    if (skip) return

    setLoading(true)
    const subscription = client
      .subscribe<Subscription, SubscriptionVariables>({
        query,
        variables,
      })
      .subscribe({
        start() {
          setLoading(true)
        },
        next(data) {
          setData(data.data)
          setLoading(false)
        },
        error(err) {
          setError(err)
          setLoading(false)
        },
      })

    return () => {
      if (!subscription.closed) {
        subscription.unsubscribe()
      }
    }
  }, [skip])

  return { loading, error, data }
}
