import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material'
import React, { ReactElement } from 'react'
import Menu from '@mui/material/Menu'
import { ReactNode } from 'react'
import { UserMenuItemType } from '../types/global.d'

export type UserMenuItem = {
  icon?: ReactElement
  label?: string
  type: UserMenuItemType
  onClick?: () => void
  value?: string
}

export type UserMenuProps = {
  items?: UserMenuItem[]
  children: ReactNode
  onMenuItemClick: (menuItem: UserMenuItem) => void
}

const UserMenu = ({ children, onMenuItemClick, items }: UserMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  let menuItems
  if (Array.isArray(items)) {
    menuItems = items.map((menuItem, index) => {
      const { icon, label, type } = menuItem

      if (type === UserMenuItemType.SEPERATOR) {
        return <Divider key={`UserMenuItem_Divider_${index}`} />
      }

      return (
        <MenuItem
          key={`UserMenuItem_${label}`}
          onClick={() => onMenuItemClick(menuItem)}
        >
          {icon && <ListItemIcon>{icon}</ListItemIcon>}
          {label && <ListItemText>{label}</ListItemText>}
        </MenuItem>
      )
    })
  }

  return (
    <>
      <IconButton aria-label="User Menu" onClick={handleClick} sx={{ px: 0 }}>
        {children}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {menuItems}
      </Menu>
    </>
  )
}

export default UserMenu
