import { JWT } from 'next-auth/jwt'
import { signOut } from 'next-auth/react'

export const getBearerToken = async (): Promise<string | undefined> => {
  const data = await fetch(`${process.env.AUTH0_ISSUER_BASE_URL}/oauth/token`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: process.env.AUTH0_CLIENT_ID,
      client_secret: process.env.AUTH0_CLIENT_SECRET,
      audience: `${process.env.AUTH0_ISSUER_BASE_URL}/api/v2/`,
      grant_type: `client_credentials`,
    }),
  })
  const { access_token } = await data.json()
  return access_token
}

export type Auth0TokenResponse = {
  access_token: string
  refresh_token: string
  id_token: string
  scope: string
  expires_in: number
  token_type: string
}

export const getAccessToken = async (
  refreshToken: string
): Promise<Auth0TokenResponse | undefined> => {
  try {
    const data = await fetch(
      `${process.env.AUTH0_ISSUER_BASE_URL}/oauth/token`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          client_id: process.env.AUTH0_CLIENT_ID,
          client_secret: process.env.AUTH0_CLIENT_SECRET,
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
        }),
      }
    )

    const result = await data.json()

    return result
  } catch (error) {
    console.error('Could get accesstoken', error)

    return undefined
  }
}

export const verifyAuth0Password = async (
  username: string,
  password: string
) => {
  const res = await fetch(`${process.env.AUTH0_ISSUER_BASE_URL}/oauth/token`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      client_id: process.env.AUTH0_CLIENT_ID,
      client_secret: process.env.AUTH0_CLIENT_SECRET,
      audience: `${process.env.AUTH0_ISSUER_BASE_URL}/api/v2/`,
      grant_type: 'password',
      username,
      password,
    }),
  })

  return res.status === 200
}

export const getAuth0UsersByEmail = async (
  bearerToken: string,
  email: string
): Promise<
  {
    identities: {
      provider: string
      user_id: string
      connection: string
      isSocial: boolean
    }[]
    user_id: string
  }[]
> => {
  const str = `/api/v2/users-by-email?fields=user_id,identities&include_fields=true&email=${encodeURIComponent(
    email
  )}`

  const data = await fetch(`${process.env.AUTH0_ISSUER_BASE_URL}${str}`, {
    method: 'GET',
    headers: { authorization: `Bearer ${bearerToken}` },
  })

  if (data.status > 199 && data.status > 300) {
    throw Error(data.statusText)
  }
  return (await data.json()) || []
}

export const updateAuth0User = async (
  bearerToken: string,
  userId: string,
  payload: object
): Promise<Response> => {
  return fetch(`${process.env.AUTH0_ISSUER_BASE_URL}/api/v2/users/${userId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${bearerToken}`,
    },
    body: JSON.stringify(payload),
  })
}

export const setAuth0PasswordByUserId = async (
  bearerToken: string,
  userId: string,
  password: string
): Promise<Response> => {
  return updateAuth0User(bearerToken, userId, { password })
}

export const handleSignOut = async () => {
  await signOut({ redirect: false })
  window.location.href = `${
    process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL
  }/v2/logout?client_id=${
    process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID
  }&returnTo=${encodeURIComponent(process.env.NEXT_PUBLIC_VERCEL_URL || '')}`
}

export type Auth0Token = {
  accessToken?: string
  accessTokenExpires: number
  idToken?: string
  refreshToken?: string
}

export const refreshAuth0Token = async ({
  accessToken,
  accessTokenExpires,
  idToken,
  refreshToken,
}: JWT): Promise<Auth0Token> => {
  try {
    const refreshedTokens = await getAccessToken(refreshToken || '')

    if (refreshedTokens) {
      const accessTokenExpires = Date.now() + refreshedTokens.expires_in * 1000

      const result = {
        accessToken: refreshedTokens.access_token,
        accessTokenExpires,
        idToken: refreshedTokens.id_token,
        refreshToken: refreshedTokens.refresh_token ?? refreshToken,
      }

      return result
    }
  } catch (error) {
    console.error('Could not refresh auth0 accesstoken', error)
  }

  // fallback to old token
  return {
    accessToken,
    accessTokenExpires,
    idToken,
    refreshToken,
  }
}

export const createUser = async (
  bearerToken: string,
  email: string,
  password: string
) => {
  const result = await fetch(
    `${process.env.AUTH0_ISSUER_BASE_URL}/api/v2/users`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${bearerToken}`,
      },

      body: JSON.stringify({
        blocked: false,
        connection: 'Username-Password-Authentication',
        email_verified: true,
        email,
        password,
        verify_email: false,
      }),
    }
  )

  return result.status
}
