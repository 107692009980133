import { ApolloClient } from '@apollo/client'
import {
  GetReadingPlanByUserIdQuery,
  GetReadingPlanByUserIdQueryVariables,
} from '../../../generated/graphql'

import GetReadingPlanByUserId from '../graphql/queries/GetReadingPlanByUserId'

export default async function getReadingPlanByUserId(
  apolloClient: ApolloClient<object>,
  variables: GetReadingPlanByUserIdQueryVariables
) {
  const { data } = await apolloClient.query<
    GetReadingPlanByUserIdQuery,
    GetReadingPlanByUserIdQueryVariables
  >({
    fetchPolicy: 'no-cache',
    query: GetReadingPlanByUserId,
    variables,
  })

  if (data.readingplan.length > 0) {
    return data.readingplan[0]
  }

  return undefined
}
