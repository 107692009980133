import { Avatar, Badge, IconButton, Typography } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'
import COLORS from '../styles/colors'
import { getInitials } from '../util/getInitials'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

export type UserBadgeProps = {
  name?: string
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#ff2727',
    color: '#ff2727',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      content: '""',
    },
  },
}))

const UserBadge = ({ name }: UserBadgeProps) => {
  return (
    <Avatar
      alt={name}
      sx={{
        background: 'none',
        border: `1px solid ${COLORS.greyDark}`,
        height: '40px',
        width: '40px',
      }}
    >
      <Typography color={COLORS.greyDark} variant="menuItem">
        {typeof name === 'string' && name.length > 0 ? (
          getInitials(name)
        ) : (
          <FontAwesomeIcon
            color="inherit"
            icon={faUser}
            style={{ height: '18px', width: '16px' }}
          />
        )}
      </Typography>
    </Avatar>
  )
}

export default UserBadge
