import { createAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from './../../../store'

export type ChatDrawerState = {
  open: boolean
  toUserId?: string
}

const initialState: ChatDrawerState = {
  open: false,
}

export const setChatToUserId = createAction<string | undefined>(
  'chatDrawer/setToUserId'
)
export const setChatOpen = createAction<boolean>('chatDrawer/setChatOpen')

export const toggleChat = createAction<void>('chatDrawer/toggleChat')

export const chatDrawerSlice = createSlice({
  name: 'chatDrawer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(toggleChat, (state) => {
      state.open = !state.open
    })
    builder.addCase(setChatOpen, (state, { payload }) => {
      state.open = payload
    })
    builder.addCase(setChatToUserId, (state, { payload }) => {
      state.toUserId = payload
    })
  },
})

export const selectChatDrawerOpen = ({ chatDrawer }: RootState) => {
  return chatDrawer.open
}

export const selectChatToUserId = ({ chatDrawer }: RootState) => {
  return chatDrawer.toUserId
}
