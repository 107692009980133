import { ApolloClient, gql } from '@apollo/client'
import {
  ChatRoomGetMessageTypesQuery,
  ChatRoomGetMessageTypesQueryVariables,
} from '../../../generated/graphql'

const GET_DATA = gql`
  query ChatRoomGetMessageTypes {
    chat_message_type {
      id
      name
    }
  }
`

export async function getMessageTypes(client: ApolloClient<object>) {
  return client.query<
    ChatRoomGetMessageTypesQuery,
    ChatRoomGetMessageTypesQueryVariables
  >({
    fetchPolicy: 'no-cache',
    query: GET_DATA,
  })
}
