import { gql } from '@apollo/client'

const GetOrganisationList = gql`
  query GetOrganisationList(
    $nameWhere: String_comparison_exp
    $parentWhere: uuid_comparison_exp
  ) {
    org(
      order_by: { name: asc }
      where: { name: $nameWhere, parent: $parentWhere }
    ) {
      id
      name
      created
      parent

      users_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export default GetOrganisationList
