import * as Sentry from '@sentry/nextjs'

export default function getIntlDate(
  date: Date,
  locale: string,
  options?: Intl.DateTimeFormatOptions
) {
  const locales = [locale, 'en-US']

  if (!(date instanceof Date)) {
    Sentry.captureMessage('Date is not defined / not a date')
    return 'N/A'
  }

  return new Intl.DateTimeFormat(locales, options).format(date)
}
