import { combineReducers } from 'redux'
import { chatDrawerSlice } from './features/Chat/slices/chatDrawerSlice'
import { localizationSlice } from './features/localization/slices/localizationSlice'
import { notificationListSlice } from './features/Chat/slices/notificationListSlice'
import { chatMessageSlice } from './features/Chat/slices/chatMessageSlice'
import { chatRoomSlice } from './features/Chat/slices/chatRoomSlice'
import { chatMessageTypesSlice } from './features/Chat/slices/chatMessageTypesSlice'
import { notificationFiltersSlice } from './features/Chat/slices/notificationFilters'
import { readingLobbySlice } from './features/readingLobby/slices/readingLobbySlice'
import { readingPlanSlice } from './features/readingPlan/slices/readingPlanSlice'
import { onboardingSlice } from './features/onboarding/slices/onboardingSlice'
import { organisationListSlice } from './features/organizations/slices/organisationListSlice'
import { readingRoomSlice } from './features/readingRoom/slices/readingRoomSlice'

const rootReducer = combineReducers({
  chatDrawer: chatDrawerSlice.reducer,
  chatMessages: chatMessageSlice.reducer,
  chatMessageTypes: chatMessageTypesSlice.reducer,
  chatRooms: chatRoomSlice.reducer,
  localization: localizationSlice.reducer,
  onboarding: onboardingSlice.reducer,
  organisationList: organisationListSlice.reducer,
  notificationFilters: notificationFiltersSlice.reducer,
  notifications: notificationListSlice.reducer,
  readingLobby: readingLobbySlice.reducer,
  readingPlan: readingPlanSlice.reducer,
  readingRoom: readingRoomSlice.reducer,
})

export default rootReducer
