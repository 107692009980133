import { gql } from '@apollo/client'

export default gql`
  mutation UpdateOnboardingReadingPlanAccept($readingPlanId: uuid!) {
    update_readingplan_by_pk(
      _set: { activated: "now()" }
      pk_columns: { id: $readingPlanId }
    ) {
      activated
    }
  }
`
