import { ApolloClient, FetchResult } from '@apollo/client'
import {
  InsertReadingRoomChatMessageMutation,
  InsertReadingRoomChatMessageMutationVariables,
} from '../../../generated/graphql'
import InsertReadingRoomChatMessage from '../graphql/mutations/InsertReadingRoomChatMessage'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../../store'

const sendReadingRoomChatMessage = createAsyncThunk<
  FetchResult<InsertReadingRoomChatMessageMutation>,
  {
    apolloClient: ApolloClient<any>
    message: string
    readingId: string
  },
  { state: RootState }
>(
  'readingRoom/sendChatMessage',
  async ({ apolloClient, message, readingId: reading_id }) => {
    return apolloClient.mutate<
      InsertReadingRoomChatMessageMutation,
      InsertReadingRoomChatMessageMutationVariables
    >({
      mutation: InsertReadingRoomChatMessage,
      variables: {
        message,
        reading_id,
      },
    })
  }
)

export default sendReadingRoomChatMessage
