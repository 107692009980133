import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import {
  FieldPolicy,
  KeySpecifier,
} from '@apollo/client/cache/inmemory/policies'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import {
  getMainDefinition,
  offsetLimitPagination,
} from '@apollo/client/utilities'
import { setContext } from '@apollo/link-context'
import { createClient } from 'graphql-ws'
import _ from 'lodash'
import fetch from 'cross-fetch'

const readingKeyArgs = ['id', '$sortDirection', '$readingStartWhere']
const userKeyArgs = ['id', '$roleName']

const offsetLimitWithoutDuplicatesPagination = (
  keyArgs: FieldPolicy<any>['keyArgs'] = false,
  refPath: string = '__ref'
) => {
  return {
    keyArgs,
    merge: function (existing: any, incoming: any, _a: any) {
      var args = _a.args
      var merged = existing ? existing.slice(0) : []
      if (incoming) {
        if (args) {
          var _b = args.offset,
            offset = _b === void 0 ? 0 : _b
          for (var i = 0; i < incoming.length; ++i) {
            merged[offset + i] = incoming[i]
          }
        } else {
          merged.push.apply(merged, incoming)
        }
      }

      return _.uniqBy(merged, refPath)
    },
  }
}

export default function getApolloClient(
  token: string | undefined,
  hasuraSecret?: string | undefined
) {
  const graphQlUrl = process.env.NEXT_PUBLIC_HASURA_GRAPHQL_URL || ''
  const wsGraphUrl = graphQlUrl
    .replace('https://', 'wss://')
    .replace('http://', 'ws://')

  const httpLink = createHttpLink({
    fetch,
    uri: graphQlUrl,
  })

  const authLink = setContext(async () => {
    const headers: { [key: string]: string } = {}

    if (hasuraSecret) {
      headers['x-hasura-admin-secret'] = hasuraSecret
    }

    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }

    return {
      headers,
    }
  })

  const wsLink =
    typeof window === 'object'
      ? new GraphQLWsLink(
          createClient({
            url: wsGraphUrl,
            connectionParams: {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          })
        )
      : null

  const link =
    typeof window === 'object'
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query)
            return (
              definition.kind === 'OperationDefinition' &&
              definition.operation === 'subscription'
            )
          },
          authLink.concat(wsLink as GraphQLWsLink),
          authLink.concat(httpLink)
        )
      : authLink.concat(httpLink)

  return new ApolloClient({
    link,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            reading: offsetLimitWithoutDuplicatesPagination(readingKeyArgs),
            readings_by_participationstate:
              offsetLimitWithoutDuplicatesPagination(readingKeyArgs),
            view_users_with_next_reading:
              offsetLimitWithoutDuplicatesPagination(userKeyArgs, 'user_id'),
          },
        },
      },
    }),
    connectToDevTools: process.env.NODE_ENV === 'development',
  })
}
