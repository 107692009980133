import { gql } from '@apollo/client'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { useTranslation } from 'next-i18next'
import Feedback from '../../../components/Feedback'
import { GetChatMessageStatusSubscription } from '../../../generated/graphql'
import useCustomSubscription from '../../../hooks/useCustomSubscription'
import COLORS from '../../../styles/colors'
import { HLO } from '../../../types/global'
import ChatDate from '../components/ChatDate'
import getFormatedChatMessageEvent from '../util/getFormatedChatMessageEvent'
import { getIconByRole } from '../util/getIconByRole'
import NotificationButton from './NotificationButton'

const GET_CHAT_ROOMS = gql`
  subscription GetChatMessageStatus($userId: uuid) {
    chat_room(
      where: { active: { _eq: true }, owner_user_id: { _eq: $userId } }
    ) {
      owner_user_id
      user_id

      user {
        person {
          name
          pronouns
        }
      }

      user {
        chatMessages(
          limit: 1
          order_by: [{ created: desc }]
          where: { chatMessageType: { name: { _in: ["message", "joined"] } } }
        ) {
          created
          from_user_id
          unread
          value
          fromPersonName
          toPersonName
          user_id
          fromUser {
            role {
              name
            }
            person {
              pronouns
            }
          }
          chatMessageType {
            id
            name
          }
        }
      }
    }
  }
`

export type RoomListProps = {
  currentUserId?: string
  language?: HLO.Language
  onSelect: (id: string) => void
  selected?: string
}

const ChatRoomList = ({
  currentUserId,
  language,
  onSelect,
  selected,
}: RoomListProps) => {
  const { t } = useTranslation('Chat')

  const { loading, error, data } =
    useCustomSubscription<GetChatMessageStatusSubscription>({
      skip: !currentUserId,
      query: GET_CHAT_ROOMS,
      variables: {
        userId: currentUserId,
      },
    })

  const rooms = data?.chat_room || []

  const handleOnClick = (id: string) => {
    onSelect(id)
  }

  return (
    <Stack height="100%" width="100%">
      {error && (
        <>
          <Feedback type="error" text="Could not get list of chats" />
          <Feedback type="error" text={error.message} />
        </>
      )}
      {loading ? (
        <Feedback type="loading" />
      ) : (
        <List
          sx={{
            height: '100%',
            overflowY: 'auto',
            padding: '32px 16px 32px 16px',
          }}
        >
          {rooms.length === 0 ? (
            <Stack alignItems="center" gap="16px">
              <Feedback
                type="error"
                text={t('chatRoomList.noChatRoomsJoinedTitle')}
                additionalContent={t(
                  'chatRoomList.noChatRoomsJoinedDescription'
                )}
              />
              <NotificationButton />
            </Stack>
          ) : (
            rooms.map(({ user }) => {
              if (
                Array.isArray(user.chatMessages) &&
                user.chatMessages.length > 0
              ) {
                const {
                  created,
                  user_id: userId,
                  value,
                  fromUser,
                  toPersonName,
                  chatMessageType,
                  from_user_id: fromUserId,

                  unread,
                } = user.chatMessages[0]

                const isSelected = userId === selected
                const isUnread = unread && fromUserId === userId

                const chatMessage =
                  chatMessageType.name === 'message'
                    ? value || ''
                    : getFormatedChatMessageEvent(
                        t(`events.${chatMessageType.name}`),
                        toPersonName || ''
                      )

                return (
                  <ListItemButton
                    onClick={() => handleOnClick(userId)}
                    key={userId}
                    sx={{
                      marginBottom: '16px',
                      border: isUnread
                        ? `1px solid ${COLORS.burnedOrange}`
                        : `1px solid ${COLORS.borderGrey}`,
                      borderRadius: '8px',
                      backgroundColor: isSelected ? COLORS.grey : '#FFF',
                    }}
                  >
                    <>
                      <ListItemText
                        primary={
                          <Stack direction="row" justifyContent="space-between">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing="16.5px"
                            >
                              {fromUser && (
                                <FontAwesomeIcon
                                  fontSize="28px"
                                  color={COLORS.grey}
                                  icon={getIconByRole(
                                    fromUser.role.name as HLO.Role
                                  )}
                                />
                              )}
                              <Typography
                                variant="inter_h6"
                                color={COLORS.greyDark}
                              >
                                {toPersonName}
                              </Typography>
                            </Stack>
                            {isUnread ? (
                              <FontAwesomeIcon
                                fontSize="12px"
                                color={COLORS.burnedOrange}
                                icon={faCircle}
                              />
                            ) : (
                              ''
                            )}
                          </Stack>
                        }
                        secondary={
                          <Stack
                            mt="14px"
                            direction="row"
                            justifyContent="space-between"
                          >
                            <Typography
                              variant={
                                isUnread ? 'inter_h8' : 'inter_inputText'
                              }
                              color={COLORS.greyDark}
                            >{`${chatMessage.trim().substring(0, 30)}${
                              (chatMessage || '').length >= 30 ? '... ' : ' '
                            }`}</Typography>
                            <ChatDate
                              date={new Date(created)}
                              language={language}
                            />
                          </Stack>
                        }
                      />
                    </>
                  </ListItemButton>
                )
              }
            })
          )}
        </List>
      )}
    </Stack>
  )
}

export default ChatRoomList
