import { createAction, createSlice } from '@reduxjs/toolkit'
import { GetReadingByIdQuery } from '../../../generated/graphql'
import { LiveParticipants } from '../hooks/useLiveParticipants'
import { RootState } from './../../../store'

const actionPrefix = `readingLobby`

export type ReadingLobbyState = {
  liveParticipants?: LiveParticipants
  reading?: GetReadingByIdQuery['reading'][0]
  readingCancelled?: boolean
  readingError?: boolean
  readingLoading?: boolean
  sessionStarted: boolean
}

const initialState: ReadingLobbyState = {
  sessionStarted: false,
}

export const setReadingLobbyLiveParticipants = createAction<LiveParticipants>(
  `${actionPrefix}/setLiveParticipants`
)

export const setReadingLobbyReading = createAction<
  GetReadingByIdQuery['reading'][0]
>(`${actionPrefix}/setReading`)

export const setReadingLobbyReadingCancelled = createAction<boolean>(
  `${actionPrefix}/setReadingCancelled`
)

export const setReadingLobbyReadingError = createAction<boolean>(
  `${actionPrefix}/setReadingError`
)

export const setReadingLobbyReadingLoading = createAction<boolean>(
  `${actionPrefix}/setReadingLoading`
)

export const setReadingLobbySessionStarted = createAction<boolean>(
  `${actionPrefix}/setSessionStarted`
)

export const readingLobbySlice = createSlice({
  name: 'readingLobby',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setReadingLobbyReading, (state, { payload }) => {
        state.reading = payload
      })
      .addCase(setReadingLobbyReadingCancelled, (state, { payload }) => {
        state.readingCancelled = payload
      })
      .addCase(setReadingLobbyReadingError, (state, { payload }) => {
        state.readingLoading = payload
      })
      .addCase(setReadingLobbyReadingLoading, (state, { payload }) => {
        state.readingLoading = payload
      })
      .addCase(setReadingLobbyLiveParticipants, (state, { payload }) => {
        state.liveParticipants = payload
      })
      .addCase(setReadingLobbySessionStarted, (state, { payload }) => {
        state.sessionStarted = payload
      })
  },
})

export const selectReadingLobbyLiveParticipants = ({
  readingLobby,
}: RootState) => {
  return readingLobby.liveParticipants || {}
}

export const selectReadingLobbySessionReading = ({
  readingLobby,
}: RootState) => {
  return readingLobby.reading
}

export const selectReadingLobbySessionReadingCancelled = ({
  readingLobby,
}: RootState) => {
  return !!readingLobby.readingCancelled
}

export const selectReadingLobbySessionReadingError = ({
  readingLobby,
}: RootState) => {
  return !!readingLobby.readingError
}

export const selectReadingLobbySessionReadingLoading = ({
  readingLobby,
}: RootState) => {
  return !!readingLobby.readingLoading
}

export const selectReadingLobbySessionStarted = ({
  readingLobby,
}: RootState) => {
  return !!readingLobby.sessionStarted
}
