import { Box } from '@mui/system'
import { ReactNode } from 'react'
import COLORS from '../styles/colors'

export type MainLayoutProps = {
  children: ReactNode
}

export const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        background: COLORS.beige,
      }}
    >
      <Box sx={{ background: COLORS.beige }}>{children}</Box>
    </Box>
  )
}
