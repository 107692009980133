import { ApolloClient, FetchResult } from '@apollo/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  UpdateOrganisationByIdMutation,
  UpdateOrganisationByIdMutationVariables,
} from '../../../generated/graphql'
import { RootState } from '../../../store'
import UpdateOrganisationById from '../graphql/mutations/UpdateOrganisationById'

export const updateOrganisationById = createAsyncThunk<
  FetchResult<UpdateOrganisationByIdMutation>,
  {
    client: ApolloClient<object>
    variables: UpdateOrganisationByIdMutationVariables
  },
  { state: RootState }
>('organisations/updateById', async ({ client, variables }) => {
  return await client.mutate<
    UpdateOrganisationByIdMutation,
    UpdateOrganisationByIdMutationVariables
  >({
    fetchPolicy: 'no-cache',
    mutation: UpdateOrganisationById,
    variables,
  })
})
