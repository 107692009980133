import { ListItem, ListItemBaseProps } from '@mui/material'
import { RefObject } from 'react'

export type ChatMessageListItemProps = ListItemBaseProps & {
  ref?:
    | ((instance: HTMLLIElement | null) => void)
    | RefObject<HTMLLIElement>
    | null
    | undefined
}

const ChatMessageListItem = (props: ChatMessageListItemProps) => {
  const { children, ref, sx } = props

  return (
    <ListItem
      ref={ref}
      sx={{
        p: '12px',
        ...sx,
      }}
    >
      {children}
    </ListItem>
  )
}

export default ChatMessageListItem
