import { Stack, Typography } from '@mui/material'
import COLORS from '../../../styles/colors'
import { HLO } from '../../../types/global'
import getLocalTimezone from '../../timezone/utils/getLocalTimezone'
import getNavigatorLanguage from '../../timezone/utils/getNavigatorLanguage'

export type ChatDateProps = {
  date: Date
  language?: HLO.Language
}

const ChatDate = ({ date, language }: ChatDateProps) => {
  const day = date.toLocaleString('en-us', {
    day: 'numeric',
    timeZone: getLocalTimezone(),
  })
  const shortMonth = date.toLocaleString('en-us', {
    month: 'short',
    timeZone: getLocalTimezone(),
  })
  const year = date.getFullYear()
  const isToday = date.toDateString() === new Date().toDateString()

  return (
    <Stack alignItems="end">
      <Typography color={COLORS.grey} variant="inter_inputText">
        {!isToday && (
          <>
            {day} {shortMonth} {year}{' '}
          </>
        )}
        {date.toLocaleTimeString(language?.isocode || getNavigatorLanguage(), {
          hour: '2-digit',
          minute: '2-digit',
          timeZone: getLocalTimezone(),
        })}
      </Typography>
    </Stack>
  )
}

export default ChatDate
