import { gql } from '@apollo/client'

export default gql`
  query GetOnboardingReadingPlan($userId: uuid!) {
    user_by_pk(id: $userId) {
      readingplan {
        activated
        id
        initialreadings
        remainingreadings
        pillarconstraints {
          pillar {
            id
            description
            title
          }
        }
        readingPlanDuration {
          translation {
            name
          }
        }
      }
    }
  }
`
